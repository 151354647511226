export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET = "RESET";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_MODAL_SIGN = "SET_MODAL_SIGN";

export const SET_CAR_INFO = "SET_CAR_INFO";
export const GET_CAR_INFO = "GET_CAR_INFO";