import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { forgotPassword} from "../../store/actions/auth";
import { clearMessage } from "../../store/actions/message";
import { useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';


const ForgotPassword = () => {
  let navigate = useNavigate();
  const { userEmail } = useParams();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState('');
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const location = useLocation();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn]);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };


  const isValidate = () => {
    if (!email) {
      setError('The email is required!');
      return false;
    } else if (!isEmail(email)) {
      setError('The email is not valid.');
      return false;
    }

    setError('');
    return true;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (!isValidate()) return;

    dispatch(forgotPassword(email))
  };

  return (
    <main>
    <CssBaseline />
    <Sheet
    sx={{
      width: 300,
      mx: 'auto', // margin left & right
      my: 4, // margin top & bottom
      py: 3, // padding top & bottom
      px: 2, // padding left & right
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      borderRadius: 'sm',
      boxShadow: 'md',
    }}
    variant="outlined"
  >
    <div>
      <Typography level="h4" component="h1" textAlign={"center"}>
        <b>RESET YOUR PASSWORD!</b>
      </Typography>
    </div>
    <FormControl>
      <FormLabel>Email</FormLabel>
      <Input
        // html input attribute
        name="email"
        type="email"
        placeholder={userEmail}
        onChange={onChangeEmail}
      />
    </FormControl>
    {error && <Alert variant='danger'>{error}</Alert>}
    <Button sx={{ mt: 1 /* margin top */ }} onClick={SubmitHandler}>SEND RESET LINK</Button>
    
  </Sheet>
  </main>  
  );
};

export default ForgotPassword;
