import { Autocomplete, Box, Button, Grid, Input, Stack, Typography } from "@mui/joy";
import React,{useEffect,useState} from "react";
import { useSelector } from "react-redux";
import userService from "../../services/user.service";
import countries from '../../utils/countries.json';
import roles from '../../utils/roles.json';
import roles1 from '../../utils/roles1.json';
import driveCountries from '../../utils/drive_countries.json';
import * as helper from '../../utils/helper';
import states from '../../utils/states.json';
import { showToast } from "../../utils/toasts";
import UploadButton from "./UploadButton";
import { Link, useLocation, useNavigate } from "react-router-dom";

const initialProfile = { phone: '', email: '', license_number: '', license_country: '', license_state: '', dob: helper.getDateStringWithYear(-25), expiration: helper.getDateStringWithYear(1),
                         first_name: '', last_name: '', street: '', city: '', zip: '', country: '', license_front: '', license_back: ''};

const inputArray = [
    { key: 'phone', type: 'text', label: 'Phone' },
    { key: 'email', type: 'text', label: 'Email', disabled: true },
    { key: 'license_number', type: 'text', label: 'License Number' },
    { key: 'license_country', type: 'auto', label: 'License Country', options: driveCountries, placeholder: 'Select country' },
    { key: 'license_state', type: 'auto', label: 'State', options: states, placeholder: 'Select state' },
    { key: 'dob', type: 'date', label: 'DOB', min: '1950-01-01', max: '2024-12-31' },
    { key: 'expiration', type: 'date', label: 'Expiration', min: '2023-01-01', max: '2035-12-31'  },
    { key: 'first_name', type: 'text', label: 'First Name' },
    { key: 'last_name', type: 'text', label: 'Last Name' },
    { key: 'street', type: 'text', label: 'Street' },
    { key: 'city', type: 'text', label: 'City' },
    { key: 'zip', type: 'text', label: 'Zip' },
    { key: 'country', type: 'auto', label: 'Country', options: countries, placeholder: 'Select country' },

]

const ProfileEdit = ({data, setData, userId, ...props}) => {
    const location = useLocation();
    let navigate = useNavigate();
    const [dataTmp,setDataTmp] = useState(data);
    const [fieldArray,setFieldArray] = useState(inputArray);
    // const { user } = useSelector(state => state.auth);
    const queryParams = new URLSearchParams(location.search);
    const isCheck = queryParams.get('carId') ? true: false;
    const roleArray={ key: 'role', type: 'auto', label: 'Role', options: roles, placeholder: 'Select role' };
    const roleArray1={ key: 'role', type: 'auto', label: 'Role', options: roles1, placeholder: 'Select role' };
    useEffect(()=>{
        
    },[data]);
    const handleChange = (key, newValue) => {
        console.log("newValue:", newValue);
        let tempData = {...data};
        tempData[key] = newValue;
        setData(tempData);
    }

    const handleSave = async () => {
        let tempData = {...data};
        tempData.user_id = userId;
        console.log("before save:", tempData);
        let res = await userService.saveProfile(tempData);
        console.log("save profile : ", res.data);
        if(res.data.success){
            setData({...initialProfile, ...res.data.data});
            showToast('success', res.data.msg);
            if(isCheck){
                navigate(`/book?${queryParams}`)
            }
        }else{
            showToast('error', res.data.msg);
        }
    }
    return (
        <Grid container spacing={{xs: 1, md: 4}}>
            <Grid lg={5}  md={6} xs={12}>
                <Stack spacing={2}>
                    {fieldArray.map((item) => (
                        <Stack key={item.key} direction='row' alignItems={'center'} spacing={2}>
                            <Typography level='title-sm' sx={{width: 120}}>{item.label}</Typography>
                            {item.type === 'text' && 
                            <>
                                <Input type='text' id={item.key} variant='soft' sx={{flex: 1}} value={data[item.key]} onChange={(e)=>handleChange(item.key, e.target.value)} disabled={item.disabled}/>
                            </>}
                            {item.type === 'date' && 
                            <>
                                <Input type="date" id={item.key} variant='soft' sx={{flex: 1}} value={data[item.key]} onChange={(e)=>handleChange(item.key, e.target.value)} slotProps={{input: {min: item.min, max: item.max,},}}/>
                            </>}
                            {item.type === 'auto' && 
                            <>
                                <Autocomplete id={item.key} placeholder={item.placeholder} value={helper.getAutoObject(data[item.key], item.options)} options={item.options} variant='soft' sx={{flex: 1}} onChange={(e, newValue) => handleChange(item.key, newValue ? newValue.code: '')}/> 
                            </>}
                        </Stack>
                    ))}
                    {/* <Stack key={'role'} direction='row' alignItems={'center'} spacing={2}>
                    {data.role === 'admin'?(<>
                        <Typography level='title-sm' sx={{width: 120}}>{roleArray.label}</Typography>
                        <Autocomplete id={roleArray.key} placeholder={roleArray.placeholder} value={helper.getAutoObject(data[roleArray.key], roleArray.options)} options={roleArray.options} variant='soft' sx={{flex: 1}} onChange={(e, newValue) => handleChange(roleArray.key, newValue ? newValue.code: '')}/> 
                    </>) :(<>
                        <Typography level='title-sm' sx={{width: 120}}>{roleArray1.label}</Typography>
                        <Autocomplete id={roleArray1.key} placeholder={roleArray1.placeholder} value={helper.getAutoObject(data[roleArray1.key], roleArray1.options)} options={roleArray1.options} variant='soft' sx={{flex: 1}} onChange={(e, newValue) => handleChange(roleArray1.key, newValue ? newValue.code: '')}/> 
                    </>)}
                    </Stack> */}
                    <Stack direction='row' alignItems={'center'}>
                        <Typography level="title-lg" sx={{width: 120}}>Private Page</Typography>
                        <Button component={Link} variant='plain' size='lg' to={`/fleet/${userId}`}>https://driveit.ai/fleet/{userId}</Button>
                    </Stack>
                    
                </Stack>
            </Grid>
            <Grid lg={2} md={1}></Grid>
            <Grid lg={5} md={5} xs={12} mt={{xs: 4, md: 0}}>
                <Stack spacing={6}>
                    <UploadButton kind="license" path={data.license_front} onChange={(newPath) => handleChange('license_front', newPath)}>License Front</UploadButton>
                    <UploadButton kind="license" path={data.license_back} onChange={(newPath) => handleChange('license_back', newPath)}>License Back</UploadButton>
                </Stack>
            </Grid>
            <Grid xs={12} sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button variant='solid' onClick={()=>handleSave()}>{isCheck ? 'Save and Continue' : 'Save Profile'}</Button>
            </Grid>
        </Grid>
    );
};

export default ProfileEdit;
