import { Input, Stack, Typography, Button } from "@mui/joy";
import { useEffect, useState } from "react";
import * as helper from '../../utils/helper';
import { useNavigate } from "react-router-dom";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { DatePicker} from 'rsuite';
import { FaClock } from 'react-icons/fa';
import isBefore from 'date-fns/isBefore';

const initialState = {fd: new Date(),ft:new Date(new Date().setHours(0, 0, 0, 0)), fa: '', td: new Date(), ta: '',tt:new Date(new Date().setHours(23, 59, 59, 0))};

export default function BookComponent(props){
    const [data, setData] = useState({carId: props.carId,userId:props.userId, ...initialState});
    const [addresses,setAddresses] = useState([]);
    const [selectedZipCode, setSelectedZipCode] = useState('');
    const [selectedZipCodeTo, setSelectedZipCodeTo] = useState('');
    const [isBooking, setIsBooking] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        debugger;
        if (props.car && props.car.addresses) {
            try {
                const parsedAddresses = JSON.parse(props.car.addresses);
                setAddresses(Array.isArray(parsedAddresses) ? parsedAddresses : []);
                setSelectedZipCode(parsedAddresses[0].zip_code);
                setSelectedZipCodeTo(parsedAddresses[0].zip_code);
                setData(prev => ({...prev, fa: JSON.stringify(parsedAddresses[0])}));
                setData(prev => ({...prev, ta: JSON.stringify(parsedAddresses[0])}));
            } catch (error) {
                console.error("Error parsing addresses:", error);
                setAddresses([]);
            }
        } else {
            setAddresses([]);
        }
    }, [props.car]);

    useEffect(() => {
        debugger
        let bFlag=false;
        let bookItem;
        if(props.car.books){
            for (let i = 0; i < props.car.books.length; i++) {
                const book = props.car.books[i];
                let startDate=new Date(new Date(book.pickup_date+'T'+book.pickup_time+'Z').toLocaleString());
                let endDate=new Date(new Date(book.return_date+'T'+book.return_time+'Z').toLocaleString());
                if(helper.isDateInRange(startDate.setHours(0, 0, 0, 0),endDate.setHours(23, 59, 59, 0),props.selectDate.setHours(0, 1, 0, 0))){
                    bFlag=true;
                    bookItem=book
                    break;
                }
            }
            if(bFlag){
                setData(prev=>({...prev, fd: new Date(new Date(bookItem.pickup_date+'T'+bookItem.pickup_time+'Z').toLocaleString())}));
                setData(prev=>({...prev, td: new Date(new Date(bookItem.return_date+'T'+bookItem.return_time+'Z').toLocaleString())}));
                setData(prev=>({...prev, ft: new Date(new Date(bookItem.pickup_date+'T'+bookItem.pickup_time+'Z').toLocaleString())}));
                setData(prev=>({...prev, tt: new Date(new Date(bookItem.return_date+'T'+bookItem.return_time+'Z').toLocaleString())}));
                setData(prev => ({...prev, fa: bookItem.pickup_zip}));
                setData(prev => ({...prev, ta: bookItem.return_zip}));
                setSelectedZipCode(JSON.parse(bookItem.pickup_zip).zip_code);
                setSelectedZipCodeTo(JSON.parse(bookItem.return_zip).zip_code);
            }else{
                setData(prev=>({...prev, fd: props.selectDate}));
                setData(prev=>({...prev, td: props.selectDate}));
                setData(prev=>({...prev, ft: new Date(new Date(props.selectDate).setHours(0, 0, 0, 0))}));
                setData(prev=>({...prev, tt: new Date(new Date(props.selectDate).setHours(23, 59, 59, 0))}));
                if(props.car.addresses){
                    const parsedAddresses = JSON.parse(props.car.addresses);
                    setSelectedZipCode(parsedAddresses[0].zip_code);
                    setSelectedZipCodeTo(parsedAddresses[0].zip_code);
                }
            }
        }
        setIsBooking(false);
    }, [props.selectDate]);

    const handleChange = (e) => {
        setIsBooking(false);
        setData({...data, [e.target.name]: e.target.value});
    }
    const handleChangePickupTime=(value)=>{
        setIsBooking(false);
        console.log('ft:'+value)
        setData(prev=>({...prev, ft: value}));
    }
    const handleChangeReturnTime=(value)=>{
        setIsBooking(false);
        setData(prev=>({...prev, tt: value}));
    }
    const handleChangeFromAddressForBook = (event,newValue,) => {
        setIsBooking(false);
        const selectedAddress = addresses.find(address => address.zip_code === newValue);
        setData(prev => ({...prev, fa: JSON.stringify(selectedAddress)}));
        setSelectedZipCode(newValue);
    };

    const handleChangeToAddressForBook = (event,newValue,) => {
        setIsBooking(false);
        const selectedAddress = addresses.find(address => address.zip_code === newValue);
        setData(prev => ({...prev, ta: JSON.stringify(selectedAddress)}));
        setSelectedZipCodeTo(newValue);
    };
    const isBookingFunc = () => {
        const startDate=new Date(helper.getDateString(data.fd)+'T'+helper.getTimeString(data.ft));
        const endDate=new Date(helper.getDateString(data.td)+'T'+helper.getTimeString(data.tt));
        let evalStartDate;
        let evalEndDate;
        let bookFlag=false;
        let dailyFlag=false;
        for (let i = 0; i < props.car.books.length; i++) {
            const book = props.car.books[i];
            evalStartDate = helper.convertUTCToLocal(new Date(book.pickup_date + 'T' + book.pickup_time+'Z'));
            evalEndDate = helper.convertUTCToLocal(new Date(book.return_date + 'T' + book.return_time+'Z'));
            bookFlag = helper.isDateRangeInRange(startDate, endDate, evalStartDate, evalEndDate);
            if (bookFlag) {
                break;
            }
        }

        for (let i = 0; i < props.car.daily_settings.length; i++) {
            const daily = props.car.daily_settings[i];
            if(daily.block){
                evalStartDate = helper.convertUTCToLocal(daily.unavailable_start);
                evalEndDate = helper.convertUTCToLocal(daily.unavailable_end);
                dailyFlag = helper.isDateRangeInRange(startDate, endDate, evalStartDate, evalEndDate);
                if(dailyFlag) {
                    break;
                }
            }
        }


        return !(bookFlag || dailyFlag);
    }
    const handleBook = () => {
        debugger;
        if(isBookingFunc()){
            const queryString = new URLSearchParams(data).toString();
            navigate(`/book?${queryString}`);
            setIsBooking(false);
        }else{
            setIsBooking(true);
        }
    }

    const handleChangePickupDate=(value)=>{
        setIsBooking(false);
        setData(prev=>({...prev, fd: value}));
    }

    const handleChangeReturnDate=(value)=>{
        setIsBooking(false);
        setData(prev=>({...prev, td: value}));
    }

    return(
        <Stack spacing={2} sx={{ width: '250px' }}>
            <Stack>
                <Typography level='title-sm'>Pickup Date</Typography>
                <DatePicker shouldDisableDate={date => isBefore(date, new Date())}      format="MM-dd-yyyy" value={data.fd} onChange={(v)=>handleChangePickupDate(v)}/>
                <DatePicker format="HH:mm:ss" name='ft' caretAs={FaClock} value={data.ft} onChange={(v)=>handleChangePickupTime(v)} style={{marginTop:'10px'}}/>
            </Stack>
            <Stack>
                <Typography level="title-sm">Pickup Address</Typography>
                <Select 
                    value={selectedZipCode} 
                    name='FromAddressForBook' 
                    onChange={handleChangeFromAddressForBook}
                    sx={{ width: '100%', maxWidth: '250px' }}
                >
                    {addresses.length > 0 ? (
                        addresses.map((address, index) => (
                            <Option key={index} value={address.zip_code}>
                                {address.street_address} {address.city} {address.state} {address.country}
                            </Option>
                        ))
                    ) : (
                        <Option value="">No addresses available</Option>
                    )}
                </Select>
            </Stack>
            <Stack>
                <Typography level='title-sm'>Return Date</Typography>
                <DatePicker shouldDisableDate={date => isBefore(date, new Date())} format="MM-dd-yyyy" value={data.td} onChange={(v)=>handleChangeReturnDate(v)}/>
                <DatePicker 
                    format="HH:mm:ss" 
                    name='tt' 
                    caretAs={FaClock} 
                    value={data.tt} 
                    onChange={(v)=>handleChangeReturnTime(v)} 
                    style={{marginTop:'10px'}}
                />
            </Stack>
            <Stack>
                <Typography level='title-sm'>Return Address</Typography>
                <Select 
                    value={selectedZipCodeTo} 
                    name='ToAddressForBook' 
                    onChange={handleChangeToAddressForBook}
                    sx={{ width: '100%', maxWidth: '250px' }}
                >
                    {addresses.length > 0 ? (
                        addresses.map((address, index) => (
                            <Option key={index} value={address.zip_code}>
                                {address.street_address} {address.city} {address.state} {address.country}
                            </Option>
                        ))
                    ) : (
                        <Option value="">No addresses available</Option>
                    )}
                </Select>
            </Stack>
            {isBooking && (
                <Stack>
                    <Typography level='title-sm' color="danger">Please set booking datetime again</Typography>
                </Stack>
            )}
            <Button variant='solid' onClick={handleBook}>Continue</Button>
        </Stack>
    )
}