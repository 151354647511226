import axios from "axios";
import authHeader from "./auth-header";
import * as constants from "../utils/constants";

const API_URL = constants.REACT_APP_API_BASE_URL + "car/";

const uploadImage = (file, kind='cars') => {
    const formData = new FormData()
    formData.append('img', file);
    return axios.post(API_URL + `upload-image/${kind}`, formData, { headers: {...authHeader(), 'Content-Type': "multipart/form-data"} })
};

const addCar = (data) => {
    return axios.post(API_URL + "add", data, { headers: authHeader() })
};

const getCarInfo = (id) => {
    return axios.get(API_URL + "get-carinfo", { headers: authHeader(), params: { car_id: id } });
};

const saveCarInfo = (data) => {
    return axios.post(API_URL + "save-carinfo", data, { headers: authHeader() });
};

const getBooking = (user_id) => {
    return axios.post(API_URL + "get-booking", {user_id: user_id}, { headers: authHeader() });
};

const setBook = (data) => {
    return axios.post(API_URL + "set-book", data, { headers: authHeader() });
};

const getBook = (id) => {
    return axios.post(API_URL + "get-book", {id: id}, { headers: authHeader() });
};

const saveDailySettings = (data) => {
    return axios.post(API_URL + "save-daily-settings", data, { headers: authHeader() });
};

const removeDailySettings = (id,createdAt) => {
    return axios.get(API_URL + "remove-daily-settings", { headers: authHeader(), params: { car_id: id,createdAt: createdAt} });
};

const getCarsByUser = (id) => {
    return axios.get(API_URL + "get-cars", { headers: authHeader(), params: { user_id: id } });
};
const searchCars = (data) => {
    return axios.post(API_URL + "search-cars", data, { headers: authHeader() });
};

const checkVinExist = (vin) => {
    return axios.get(API_URL + "check-vin-exist", { headers: authHeader(), params: { vin: vin } });
};

const getVinData = (vin) => {
        // let temp =  {
        //     data:{
        //         "message": {
        //             "code": 0,
        //             "message": "ok",
        //             "message": "Invalid vin format"
        //         },
        //         "data": {
        //             "year":2015,
        //             "make":"CHEVROLET",
        //             "model":"EQUINOX",
        //             "manufacturer": "GENERAL MOTORS",
        //             "engine":" L4, 2.4L; DOHC; 16V; DI; FFV",
        //             "trim": "LTZ",
        //             "transmission": "AUTOMATIC"
        //         }
        //     }
        // }
        // return  new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve(temp);
        //     }, 1000);
        // });
        return axios.get(constants.VIN_API_URL_DECODE, 
                                        {headers: {
                                                "Content-type": "Application/json",
                                                "authorization": constants.VIN_API_AUTHORIZATION,
                                                "partner-token": constants.VIN_API_PARTNERTOKEN,
                                                },
                                        params: { vin: vin }
                                        }
                                    )
       
};

const setBookApprove = (data) => {
    return axios.post(API_URL + "set-book-approve", data, { headers: authHeader() });
};

export default {
    uploadImage,
    addCar,
    getCarsByUser,
    getVinData,
    getCarInfo,
    saveCarInfo,
    saveDailySettings,
    checkVinExist,
    searchCars,
    setBook,
    getBook,
    getBooking,
    setBookApprove,
    removeDailySettings
};