import { Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import carService from "../../services/car.service";
import { showToast } from "../../utils/toasts";
import * as helper from '../../utils/helper';
export default function Book() {
    const location = useLocation();
    const { user } = useSelector(state => state.auth);
    const [carInfo, setCarInfo] = useState(null);
    const [searchInfo, setSearchInfo] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    let navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);

    const handleBook = async () => {
        let temp ={};
        temp.car_id = carInfo.id;
        temp.user_id = user.id;
        temp.pickup_date = new Date(helper.getDateString(searchInfo.fd)+'T'+helper.getTimeString(searchInfo.ft));
        temp.pickup_zip = searchInfo.fa == 'undefined'? null:searchInfo.fa;
        temp.return_date = new Date(helper.getDateString(searchInfo.td)+'T'+helper.getTimeString(searchInfo.tt));
        temp.return_zip = searchInfo.ta== 'undefined'? null:searchInfo.ta;
        temp.pickup_time=searchInfo.ft;
        temp.return_time = searchInfo.tt;
        temp.status = 'Waiting';
        temp.color=`#${Math.floor(Math.random() * 16777215).toString(16)}`
        debugger
        let res = await carService.setBook(temp)
        console.log("Book : ", res.data);
        if(res.data.success){
            showToast('success', res.data.msg);
            navigate(`/book/${res.data.data.id}`);
        }else{
            showToast('error', res.data.msg);
        }
    }

    useEffect(() => {
        let carId = queryParams.get('carId') || '';
        let temp = {fa: queryParams.get('fa') || "", ta: queryParams.get('ta') || "" }
        
        if (queryParams.get('fd')){
            temp.fd = new Date(queryParams.get('fd'));
        }
        if (queryParams.get('td')){
            temp.td = new Date(queryParams.get('td'));
        }

        if (queryParams.get('ft')){
            temp.ft = new Date(queryParams.get('ft'));
        }
        if (queryParams.get('tt')){
            temp.tt = new Date(queryParams.get('tt'));
        }
        debugger;
        setSearchInfo(temp);

        (async () => {
            let res = await carService.getCarInfo(carId)
            console.log("Get car info : ", res.data);
            if(res.data.success){
                setCarInfo(res.data.data);
                let vtotalPrice=0;
                for(let i=0; i<res.data.data.daily_settings.length;i++){
                    vtotalPrice+=Number(res.data.data.daily_settings[i].price);
                }
                setTotalPrice(vtotalPrice);
            }else{
                showToast('error', res.data.msg);
            }
        })();

    }, [])

    return (
        <Stack flex={1} direction='row' justifyContent={'center'}>
            <Stack sx={{width: '100%', maxWidth: 600, px: {xs: 2, sm: 8, md: 4}, py: 4}}>
                <Stack mb={6} direction='row' justifyContent={'space-between'}>
                    <Typography level='h2'>Book</Typography>
                </Stack>
                {carInfo && <Stack spacing={2}>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Car Name:</Typography>
                        <Typography level='title-md' flex={1}>{carInfo.car_name}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Car Vin:</Typography>
                        <Typography level='title-md' flex={1}>{carInfo.vin}</Typography>
                    </Stack>
                    <Stack direction='row'>
                        <Typography level='body-sm'  sx={{width: 120}}>Pickup Date:</Typography>
                        <Typography level='title-md'>{helper.getDateString(searchInfo.fd)+' '+helper.getTimeString(searchInfo.ft)}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 60}}>Pickup Address:</Typography>
                        <Typography level='title-md'>
                            {searchInfo.fa != 'undefined' && searchInfo.fa? 
                                JSON.parse(searchInfo.fa).street_address + ' ' + 
                                JSON.parse(searchInfo.fa).city + ' ' + 
                                JSON.parse(searchInfo.fa).country 
                                : 'N/A'}
                        </Typography>
                    </Stack>
                    <Stack direction='row'>
                        <Typography level='body-sm'  sx={{width: 120}}>Return Date:</Typography>
                        <Typography level='title-md'>{helper.getDateString(searchInfo.td)+' '+helper.getTimeString(searchInfo.tt)}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 60}}>Return Address:</Typography>
                        <Typography level='title-md'>
                            {searchInfo.ta !== 'undefined' && searchInfo.ta ? 
                                JSON.parse(searchInfo.ta).street_address + ' ' + 
                                JSON.parse(searchInfo.ta).city + ' ' + 
                                JSON.parse(searchInfo.ta).country 
                                : 'N/A'}
                        </Typography>
                    </Stack>
                    <Stack direction='row'>
                        <Typography level='body-sm'  sx={{width: 120}}>Total Days</Typography>
                        <Typography level='title-md' flex={1}>{carInfo.daily_settings.length}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Days Cost</Typography>
                        <Typography level='title-md' flex={1}>{totalPrice}</Typography>
                    </Stack>
                </Stack>}
                <Stack direction='row' justifyContent={'center'} sx={{mt: 8}}>
                    <Button sx={{width: 120}} onClick={handleBook}>Book</Button>
                </Stack>
            </Stack>
        </Stack>
    )
}