import { AspectRatio, Box, Button, Grid, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import countries from '../../utils/countries.json';
import driveCountries from '../../utils/drive_countries.json';
import * as helper from '../../utils/helper';
import states from '../../utils/states.json';
import roles from '../../utils/roles.json'
import {  Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import userService from "../../services/user.service";
import { showToast } from "../../utils/toasts";
import { CompressOutlined } from "@mui/icons-material";

// const initialProfile = { phone: '', email: '', license_number: '', license_country: '', license_state: '', dob: helper.getDateStringWithYear(-25), expiration: helper.getDateStringWithYear(1),
//                          first_name: '', last_name: '', street: '', city: '', zip: '', country: '', license_front: '', license_back: ''};
const initialProfile = { phone: '', email: '', license_number: '', license_country: '', license_state: '', dob: '', expiration: '',
                         first_name: '', last_name: '', street: '', city: '', zip: '', country: '', license_front: '', license_back: ''};

const inputArray = [
    { key: 'phone', type: 'text', label: 'Phone' },
    { key: 'email', type: 'text', label: 'Email', disabled: true },
    { key: 'license_number', type: 'text', label: 'License Number' },
    { key: 'license_country', type: 'auto', label: 'License Country', options: driveCountries, placeholder: 'Select country' },
    { key: 'license_state', type: 'auto', label: 'State', options: states, placeholder: 'Select state' },
    { key: 'dob', type: 'date', label: 'DOB', min: '1950-01-01', max: '2024-12-31' },
    { key: 'expiration', type: 'date', label: 'Expiration', min: '2023-01-01', max: '2035-12-31'  },
    { key: 'street', type: 'text', label: 'Street' },
    { key: 'city', type: 'text', label: 'City' },
    { key: 'zip', type: 'text', label: 'Zip' },
    { key: 'country', type: 'auto', label: 'Country', options: countries, placeholder: 'Select country' },
    // { key: 'role', type: 'auto', label: 'Role', options: roles, placeholder: 'Select role' },
]

const ProfileView = () => {
    const { user } = useSelector(state => state.auth);
    const [ noProfile, setNoProfile ] = useState(false);
    const [ owner, setOwner ] = useState(false);
    const [ data, setData ] = useState(initialProfile);
    const navigate = useNavigate();


    const { userId } = useParams();

    useEffect(() => {
        setNoProfile(false);
            (async () => {
                let res = await userService.getProfile(userId)
        
                if(res.data.success){
                    let temp = res.data.data;

                    if(temp.noProfile){
                        setNoProfile(true);
                    }
                    setOwner(userId == user.id);
                    setData({...initialProfile, ...temp});
                    
                }else{
                    showToast('error', res.data.msg);
                }
            })();
    }, [userId])

    return (
        <Stack flex={1}>
            <Box sx={{
                    width: 1,
                    height: 200,
                    opacity: 0.5,
                    backgroundColor: 'background.level1',
                    backgroundImage: 'url(/assets/images/banner.png)'
                }}>
            </Box>
            <Stack flex={1} direction='row' justifyContent={'center'}>
                {noProfile && <Box my={4}>
                    <Typography level='title-lg' color='warning'>Unregistered user profile</Typography>
                </Box>}
                {!noProfile && <Stack sx={{width: '100%', maxWidth: 1200, px: {xs: 2, sm: 8, md: 4}, pb: 4}}>
                    <Box zIndex={2}>
                        <Box sx={{width: 120, height: 120, borderRadius: '50%', overflow: 'hidden', p: '4px', 
                                backgroundColor: 'background.body'}} mt={'-60px'}> 
                            <img src={data.photo} style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
                        </Box>
                    </Box>
                    <Stack mt={2} mb={3} direction='row' justifyContent={'space-between'}>
                        <Typography level='h2'>{(data.last_name || data.first_name) ? `${data.last_name} ${data.first_name}` : data.name }</Typography>
                    </Stack>
                    <Grid container spacing={{xs: 1, md: 4}}>
                        <Grid lg={5}  md={6} xs={12}>
                            <Stack spacing={2}>
                                {inputArray.map((item) => (
                                    data[item.key] && 
                                    <Stack key={item.key} direction='row' alignItems={'center'} spacing={2}>
                                        <Typography level='body-sm' sx={{width: 120}}>{item.label}:</Typography>
                                        <Typography level='body-lg' flex={1}>{data[item.key]}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>
                        <Grid lg={2} md={1}></Grid>
                        <Grid lg={5} md={5} xs={12} mt={{xs: 4, md: 0}}>
                            <Stack spacing={6}>
                                <Stack spacing={1}>
                                    <Typography level='body-sm'>License Front:</Typography>
                                    {data.license_front ?
                                        <AspectRatio minHeight="120px" maxHeight="300px" sx={{borderRadius: '8px'}}>
                                            <img alt="" src={helper.getImageUrl(data.license_front, 'license')}/>
                                        </AspectRatio>:
                                        <Typography level='title-sm'>No data</Typography>
                                    }
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography level='body-sm'>License Back:</Typography>
                                    {data.license_back ?
                                        <AspectRatio minHeight="120px" maxHeight="300px" sx={{borderRadius: '8px'}}>
                                            <img alt="" src={helper.getImageUrl(data.license_back, 'license')}/>
                                        </AspectRatio>:
                                        <Typography level='title-sm'>No data</Typography>
                                    }
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    {owner &&(<>
                    <Stack direction='r)ow' alignItems={'center'}>
                        <Typography level="title-lg" sx={{width: 120}}>Private Page</Typography>
                        <Button component={Link} variant='plain' size='lg' to={`/fleet/${userId}`}>https://driveit.ai/fleet/{userId}</Button>
                    </Stack>
                    <Stack mt={2} mb={3} direction='row' justifyContent={'space-between'}>
                        <Button component={Link}  to={`/profile/${userId}`} variant='solid' onClick={()=>{}}>{'Edit Profile'}</Button>
                    </Stack></>)}
                </Stack>}
            </Stack>
        </Stack>
    );
};

export default ProfileView;
