module.exports.REACT_APP_API_BASE_URL = process.env.NODE_ENV == 'development' ? "http://localhost:8080/api/" : "https://driveit.ai/api/"
module.exports.REACT_APP_RECAPTCHA_SITE_KEY = process.env.NODE_ENV == 'development' ? "6LfVT_EnAAAAABJvXNy0x5rl3M6QrbWNdE31vNP-" : "6LdDYPEnAAAAAGga52AMoVgdplPbTp26-l-GvF-1"
module.exports.REACT_APP_GOOGLE_OAUTH_REDIRECT = process.env.NODE_ENV == 'development' ? "http://localhost:8080/api/auth/google" : "https://driveit.ai/api/auth/google"
// module.exports.REACT_APP_GOOGLE_OAUTH_CLIENT_ID = "1034417993599-unsi25bg552j34okqkag9igb0n1va5su.apps.googleusercontent.com"
module.exports.REACT_APP_GOOGLE_OAUTH_CLIENT_ID = "532293792704-h8q7qoeqfkc5n834ktlqs57qfm5ubml3.apps.googleusercontent.com"

module.exports.VIN_API_URL_DECODE = "https://api.carmd.com/v3.0/decode"
module.exports.VIN_API_AUTHORIZATION = "Basic YzMwYjBlNzQtM2E2Mi00ZTQ0LTk5ZTYtZGQzMGI1OTliOGY3"
module.exports.VIN_API_PARTNERTOKEN = "b27e59316d474b078c3ab07ebbffe5a3"
module.exports.REACT_APP_GOOGLE_MAP_KEY = "AIzaSyCRXME7CAABpvR017b4a3g1-NQjQuoq2wA"
