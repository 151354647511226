import Select from '@mui/joy/Select';

export default function CustomSelect({onChange, children, ...props}) {

  const handleChange = (e, newValue) => {
    if(onChange){
      onChange(props.id, newValue);
    }
  }
  
  return (
    <Select
      {...props}
      onChange={handleChange}
    >
      {children}
    </Select>
  );
}