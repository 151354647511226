import { Box, Button, ButtonGroup, Stack, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import carService from "../../services/car.service";
import * as helper from "../../utils/helper";
import { showToast } from "../../utils/toasts";

const ImageTd = ({src}) => {
    return(
        <Box display='flex' 
            sx={{maxHeight: 80, maxWidth: 170, borderRadius: 4, overflow: 'hidden',
                    '&:hover': {
                        transform: 'scale(2)',
                        transition: 'transform .4s',
                        cursor: 'pointer',
                        boxShadow: 'lg',
                    }
                }}
        >
            <img src={src} style={{height: '100%', width: '100%'}}/>
        </Box>
    )
}

export default function Booking() {
    const location = useLocation();
    const { user } = useSelector(state => state.auth);
    const [bookList, setBookList] = useState([]);
    let navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);

    const handleApprove = (id, status) => {
        (async () => {
            debugger;
            let res = await carService.setBookApprove({id, status})
            console.log("Set Book Status : ", res.data);
            if(res.data.success){
                showToast('success', res.data.msg);
                let temp = [...bookList];
                for(const x of temp){
                    if(x.id === id){
                        if(status=="Remove")
                            temp = temp.filter(item => item.id !== id);
                        else
                            x.status = status;
                        break;
                    }
                }
                setBookList(temp);
            }else{
                showToast('error', res.data.msg);
            }
        })();
    }

    useEffect(() => {
        if(!user){
            navigate('/home');
            return;
        }
        (async () => {
            debugger;
            let res = await carService.getBooking(user.id)
            console.log("Get Booking List : ", res.data);
            if(res.data.success){
                setBookList(res.data.data);
            }else{
                setBookList([]);
                showToast('error', res.data.msg);
            }
        })();

    }, [])

    return (
        <Stack flex={1} direction='row' justifyContent={'center'}>
            <Stack sx={{width: '100%', maxWidth: 2000, px: {xs: 2, sm: 8, md: 4}, py: 4}}>
                <Stack mb={6} direction='row' justifyContent={'space-between'}>
                    <Typography level='h2'>Booking</Typography>
                </Stack>
                <Table>
                    <thead>
                        <tr>
                            <th>Car Name</th>
                            <th>Vin</th>
                            <th>Pickup</th>
                            <th>Return</th>
                            <th>License Number</th>
                            <th style={{textAlign: 'center'}}>License Front</th>
                            <th style={{textAlign: 'center'}}>License Back</th>
                            <th style={{textAlign: 'center'}}>Status</th>
                            <th>Action</th>
                            <th style={{textAlign: 'center'}}>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookList.map((item)=>(
                            <tr>
                                <td>{item.carInfo.car_name}</td>
                                <td>{item.carInfo.vin}</td>
                                <td>
                                    <Typography>Date: <Typography level='title-sm'>{item.pickup_date+' '+helper.getTimeString(item.pickup_time)}</Typography></Typography>
                                    <Typography>Zip: <Typography level='title-sm'>{item.zip}</Typography></Typography>
                                </td>
                                <td>
                                    <Typography>Date: <Typography level='title-sm'>{item.return_date+' '+helper.getTimeString(item.return_time)}</Typography></Typography>
                                    <Typography>Zip: <Typography level='title-sm'>{item.zip}</Typography></Typography>
                                </td>
                                <td>{item.userInfo.license_number}</td>
                                <td style={{textAlign: 'center'}}>{item.userInfo.license_front==""?<Typography>None</Typography>:<ImageTd src={helper.getImageUrl(item.userInfo.license_front, 'license')}/>}</td>
                                <td style={{textAlign: 'center'}}>{item.userInfo.license_back==""?<Typography>None</Typography>:<ImageTd src={helper.getImageUrl(item.userInfo.license_back, 'license')}/>}</td>
                                <td>
                                    <Typography level='title-sm' textAlign={'center'}>
                                        {item.status}
                                    </Typography>
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    {item.status == 'Waiting' && 
                                    <ButtonGroup>
                                        <Button color='primary' onClick={()=>handleApprove(item.id, 'Approved')}>Approve</Button>
                                        <Button color='danger' onClick={()=>handleApprove(item.id, 'Denied')}>Deny</Button>
                                        <Button color='danger' onClick={()=>handleApprove(item.id, 'Remove')}>Remove</Button>
                                    </ButtonGroup>
                                    }
                                    {item.status == 'Denied' && 
                                    <ButtonGroup>
                                        <Button color='primary' variant='outlined' onClick={()=>handleApprove(item.id, 'Approved')}>Approve</Button>
                                        <Button color='danger' variant='outlined' onClick={()=>handleApprove(item.id, 'Remove')}>Remove</Button>
                                    </ButtonGroup>}
                                    {item.status == 'Approved' && 
                                    <ButtonGroup>
                                        <Button color='danger' variant='outlined' onClick={()=>handleApprove(item.id, 'Denied')}>Deny</Button>
                                        <Button color='danger' variant='outlined' onClick={()=>handleApprove(item.id, 'Remove')}>Remove</Button>
                                    </ButtonGroup>}
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Button component={Link} to={`/profileview/${item.userInfo.user_id}`} size="sm" variant='neutral' color='warning'>user profile</Button>
                                    <Button component={Link} to={`/car-detail/${item.carInfo.id}`} size="sm" variant='neutral' color='warning'>car detail</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Stack>
        </Stack>
    )
}