import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import Login from "../../../components/Login";
import Footer from "../../../components/Footer";

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Header />
      <Login />
      <Footer />
    </Fragment>
  );
};
export default LoginPage;
