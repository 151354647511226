import * as helper from '../../utils/helper';
import AspectRatio from '@mui/joy/AspectRatio';


export default function ImageComponent({src}) {
    return (
        <AspectRatio ratio="2" minHeight={120} maxHeight={700}>
            <img src={src}/>
        </AspectRatio>
    );
}
