import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/joy";

const Error = () => {
  const { t } = useTranslation();

  return (
    <Stack flex={1} alignItems={'center'} justifyContent={'center'} spacing={2}>
        <Typography level="h1" color='neutral'>404</Typography>
        <Typography level="h2" color="warning">{t("error_page.error")}</Typography>
        <Typography level="body-md">{t("error_page.desc")}</Typography>
        <Button variant='soft' component={Link} to="/">
          {t("error_page.back_home")}
        </Button>
    </Stack>
  );
};

export default Error;
