import React from "react";

import BookView from "../../../components/Book/BookView";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";

const BookViewPage = () => {

  return (
    <div className="flex-container">
      <Header />
      <BookView />
      <Footer />
    </div>
  );
};
export default BookViewPage;
