import React from "react";

import CarDetailView from "../../../components/CarAdd/CarDetailView";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";

const CarDetailViewPage = () => {

  return (
    <>
      <Header />
      <CarDetailView/>
      <Footer />
    </>
  );
};
export default CarDetailViewPage;
