import { AspectRatio, Button, Input, Skeleton, Stack, Typography } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import carService from "../../services/car.service";
import * as helper from '../../utils/helper';

export default function UploadButton({kind='other', children, ...props}) {
    const [ status, setStatus ] = useState( props.path ? 'uploaded' : 'none');
    const [ error, setError ] = useState('');
    const inputRef = useRef(null);
    useEffect(()=>{
        console.log("profile edit status:"+status);
    });
    
    const filesSelectedHandler = (e) => {
        const file = e.target.files[0];
        if(!checkMimeType(file)){
            setError(`${file.type} is not a supported format`);
            e.target.value = null;
            return;
        }
        setStatus('uploading')
        carService.uploadImage(file, kind).then((response) => {
            let res = response.data;
            if(res.success){
                setStatus('uploaded')
                console.log("upload image :", res.fileName)
                if(props.onChange){
                    props.onChange(res.fileName);
                }
            }else{
                setStatus('none');
                setError(res.msg);
                console.log("upload image - ", res.msg);
            }
        },
            (error) => {
                setStatus('none');
                console.log("upload image - :", error);
        });
    }

    const checkMimeType = (file) => {
        const types = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
        if (types.every((type) => file.type !== type)) {
            return false;
        }
        return true;
    }

    const handleClick = (e) => {
        inputRef.current.click();
    }

    useEffect(() => {
        setStatus(props.path ? 'uploaded' : 'none');
    }, [props.path])

    return (
        <Stack spacing={1}>
            <Button variant={props.variant ? props.variant : 'outlined'} color={props.color? props.color : 'neutral'} onClick={handleClick}>{children}</Button>
            {error !== '' && <Typography color='danger' level='body-sm'>{error}</Typography>}
            <input ref={inputRef} type="file" name="file" id="file" onChange={filesSelectedHandler} accept="image/png, image/jpeg, image/jpg, image/webp" multiple
                    style = {{ display: 'none'}}/>
            {status !== 'none' && <div>
                <AspectRatio minHeight="120px" maxHeight="300px" sx={{borderRadius: '8px'}}>
                    <Skeleton loading={status != 'uploaded'} variant="overlay">
                        <img alt="" src={helper.getImageUrl(props.path, kind)}/>
                    </Skeleton>
                </AspectRatio>
            </div>}
        </Stack>
    )
}