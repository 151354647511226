import React from "react";

import Book from "../../../components/Book";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";

const BookPage = () => {

  return (
    <div className="flex-container">
      <Header />
      <Book />
      <Footer />
    </div>
  );
};
export default BookPage;
