import { Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import carService from "../../services/car.service";
import { showToast } from "../../utils/toasts";
import * as helper from '../../utils/helper';

export default function BookView() {
    const location = useLocation();
    const { user } = useSelector(state => state.auth);
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    let navigate = useNavigate();
    const { bookId } = useParams();

    useEffect(() => {
        (async () => {
            setLoaded(false);
            let res = await carService.getBook(bookId)
            console.log("AAAAA");
            console.log("Get bookView info : ", res.data);
            if(res.data.success){
                setData(res.data.data);
                let vtotalPrice=0;
                for(let i=0; i<res.data.data.dailySetting.length;i++){
                    vtotalPrice+=Number(res.data.data.dailySetting[i].price);
                }
                setTotalPrice(vtotalPrice);
                //showToast('error', "Can't find any book data");
            }else{
                showToast('error', res.data.msg);
                setData(null);
            }
            setLoaded(true);
        })();

    }, [bookId])

    return (
        <Stack flex={1} direction='row' justifyContent={'center'}>
            <Stack sx={{width: '100%', maxWidth: 600, px: {xs: 2, sm: 8, md: 4}, py: 4}}>
                <Stack mb={6} direction='row' justifyContent={'space-between'}>
                    <Typography level='h2'>Book Details</Typography>
                </Stack>
                {loaded && !data && <Stack direction={'row'} justifyContent={'center'}>
                    <Typography level='title-sm' color='warning'>No book data</Typography>
                </Stack>}
                {data && <Stack spacing={2}>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Car Name:</Typography>
                        <Typography level='title-md' flex={1}>{data.car.car_name}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Car Vin:</Typography>
                        <Typography level='title-md' flex={1}>{data.car.vin}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={3}>
                        <Typography level='body-sm'  sx={{width: 120}}>Pickup Date:</Typography>
                        <Typography level='title-md'>{(new Date(data.book.pickup_date+'T'+data.book.pickup_time+'Z')).toLocaleString()}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 60}}>Zip:</Typography>
                        <Typography level='title-md'>{data.book.pickup_zip ? JSON.parse(data.book.pickup_zip).street_address+''+JSON.parse(data.book.pickup_zip).city+''+JSON.parse(data.book.pickup_zip).country : 'N/A'}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={3}>
                        <Typography level='body-sm'  sx={{width: 120}}>Return Date:</Typography>
                        <Typography level='title-md'>{(new Date(data.book.return_date+'T'+data.book.return_time+'Z')).toLocaleString()}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 60}}>Zip:</Typography>
                        <Typography level='title-md'>{data.book.return_zip ? JSON.parse(data.book.return_zip).street_address+''+JSON.parse(data.book.return_zip).city+''+JSON.parse(data.book.return_zip).country : 'N/A'}</Typography>
                    </Stack>
                    <Stack direction='row'>
                        <Typography level='body-sm'  sx={{width: 120}}>Total Days:</Typography>
                        <Typography level='title-md' flex={1}>{data.dailySetting.length}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Days Cost:</Typography>
                        <Typography level='title-md' flex={1}>{totalPrice}</Typography>
                    </Stack>
                    <Stack direction='row' >
                        <Typography level='body-sm'  sx={{width: 120}}>Status:</Typography>
                        <Typography level='title-md' flex={1} color='primary'>{data.book.status}</Typography>
                    </Stack>
                </Stack>}
            </Stack>
        </Stack>
    )
}