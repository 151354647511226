import React, { useState, useEffect } from "react";
import CarService from '../../services/car.service'
import { Box, Input, Stack, Typography } from "@mui/joy";
import AddIcon from '@mui/icons-material/Add';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.jpg,.jpeg,.png,.webp');
    
    return fileSelector;
}

const Uploader = ({ setPreviewImages, previewImages }) => {
    const [imageValidationError, setImageValidationError] = useState(null);
    const [fileSelector] = useState(buildFileSelector);
    
    useEffect(() => {
        fileSelector.addEventListener('change', filesSelectedHandler);
        return () => {
            fileSelector.removeEventListener('change', filesSelectedHandler);
        };
    }, [fileSelector]);

    const handleFileSelect = (e) => {
        e.preventDefault();
        fileSelector.click();
    }
    
    const filesSelectedHandler = (e) => {
        if (checkMimeType(e)) {
            const files = Array.from(e.target.files);
            setImageValidationError(null);
            files.forEach((file, index) => {
                let img = {
                    id: new Date().getTime() + file.name,
                    file: file,
                    prevURL: URL.createObjectURL(file),
                    isUploaded: false,
                    uploadedFileName: ''
                }
                setPreviewImages(prevItems => {
                    return [...prevItems, img];
                });

                CarService.uploadImage(img.file, 'cars').then((response) => {
                    let res = response.data;
                    if(res.success){
                        img.isUploaded = true;
                        img.uploadedFileName = res.fileName;
                        setPreviewImages((prevItems) => {
                            const updatedItems = [...prevItems]; // Create a new array with the existing items
                            updatedItems[updatedItems.findIndex((e) => e.id == img.id)] = img; // Update the specific item at the given index
                            return updatedItems; // Set the new state with the updated array
                        });
                        console.log("upload image :", res.fileName)
                    }else{
                        setPreviewImages((prevItems) => {
                            const updatedItems = [...prevItems]; // Create a new array with the existing items
                            updatedItems.splice(updatedItems.findIndex((e) => e.id == img.id), 1); // Update the specific item at the given index
                            return updatedItems; // Set the new state with the updated array
                        });
                        setImageValidationError((prev) => {
                            return (prev ? prev : '') + res.msg + '\n';
                        });
                        console.log("upload image - ", res.msg);
                    }
                },
                    (error) => {
                        console.log("upload image - :", error);
                    });
            });
        }
    };

    const checkMimeType = (event) => {
        const { files } = event.target;
        let err = "";
        const types = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
        for (let x = 0; x < files.length; x += 1) {
            if (types.every((type) => files[x].type !== type)) {
                err += `${files[x].type} is not a supported format\n`;
            }
        }
        if (err !== "") {
            event.target.value = null;
            setImageValidationError(err);
            return false;
        }
        return true;
    }

    return (
        <Stack direction='column'  onClick={handleFileSelect}>
            <Stack direction='column' alignItems={'center'} justifyContent={'center'} 
                    sx={{
                        position: 'relative',
                        width: '80%',
                        maxWidth: 200,
                        height: 100,
                        border: '2px dashed',
                        borderColor: 'divider',
                        cursor: 'pointer'
                    }}
            >
                {/* <Input type="file" name="file" id="file" onChange={filesSelectedHandler} accept=".jpg,.jpeg,.png,.webp" multiple
                    sx = {{
                        position: 'absolute',
                        m: 0, p: 0,
                        width: 1,
                        height: 1,
                        outline: 'none',
                        opacity: 0,
                    }}
                /> */}
                <Typography level='title-lg'><AddIcon/> Add Photo</Typography>
            </Stack>
            {imageValidationError ? (
                <Typography className="error-msg">{imageValidationError}</Typography>
            ) : null}
        </Stack>
    )
}

export default Uploader;