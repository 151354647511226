import React, { Fragment } from "react";
import Header from "../../../components/header";
import ProfileView from "../../../components/Profile/ProfileView";
import Footer from "../../../components/Footer";

const ProfileViewPage = () => {
  return (
    <div className="flex-container">
      <Header />
      <ProfileView />
      <Footer />
    </div>
  );
};
export default ProfileViewPage;
