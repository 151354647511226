import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from "react";
import CarListCard from "../CarCard";

import { Box, Stack, Typography } from "@mui/joy";
import CarDetailViewLayout from './CarDetailViewLayout';

const CarGridLayout = ({list}) => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const [ selectedCar, setSelectedCar ] = useState(null);
  useEffect(()=>{
    console.log("ListList:"+JSON.stringify(list))
  },[])
  const onSelect = ( car ) => {
    setSelectedCar(car);
  }

  return (
        <Stack direction='row' 
                sx={{
                    overflow: 'hidden',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    flex: 1,
                    mt: 1,
                }}
        >
            <Stack direction='column' flex={1} gap={2} sx={{overflowX: 'auto', maxWidth: {xs: '100%', md: 800}, width: {xs: '100%', md: 1/2}, position: 'relative', px: 2, py: 2}}>
                {!isSmallScreen && <Box sx={{py: -1,}}>
                    <Typography level='title-md'><span>{list.length}</span> cars at or near address</Typography>
                </Box>}
                {list.map((item) => (
                    <Box key={item.car.id}>
                        <CarListCard car={item} direction='row' selected={item.car.id == selectedCar?.id} onSelect={onSelect}/>
                    </Box>
                ))}
            </Stack>
            <Stack direction='column' sx={{overflowX: 'auto', flex: 1, borderLeft: '1px solid', borderColor: 'divider', display: { xs: 'none', md: 'flex'}}}>
                {selectedCar && <CarDetailViewLayout car={selectedCar}/>}
            </Stack>
      </Stack>
  );
};

export default CarGridLayout;
