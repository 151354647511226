import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import carService from "../../services/car.service";
import CarDetailViewLayout from "../CarList/CarDetailViewLayout";

export default function CarDetailView({car, ...props}){
  const { carId } = useParams();
  const [carInfo, setCarInfo] = useState(null);

  useEffect(() => {
      (async () => {
        let res = await carService.getCarInfo(carId)
        console.log("Get car info : ", res.data);
        if(res.data.success){
            setCarInfo(res.data.data);
        }
      })();
  }, [carId])

  return(
    <div>
      {carInfo && <CarDetailViewLayout car={carInfo}/>}
    </div>
  )
}