import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, IconButton, Stack } from '@mui/joy';
import React, { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import * as helper from '../../utils/helper';

const Preview = ({ previewImages, setPreviewImages, pageType}) => {
    const [dragId, setDragId] = useState('');

    const deleteImage = (id) => {
        if (previewImages.length > 0) {
            const filterImages = previewImages.filter((image) => image.id !== id);
            setPreviewImages(filterImages);
        }
    };

    const handleOver = (ev) => {
        ev.preventDefault();
    };

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        const dragImage = getImageIndex(dragId)
        const dropImage = getImageIndex(ev.currentTarget.id)
        const arr = moveItem(dragImage, dropImage);
        setPreviewImages(arr);
    };

    const getImageIndex = (id) => {
        for(let i=0;i<previewImages.length; i++){
            if(previewImages[i].id == id) return i;
        }
        return -1;
    }

    const moveItem = (from, to) => {
        let temp =[...previewImages]
        const f = temp.splice(from, 1)[0];
        temp.splice(to, 0, f);
        return temp;
    }

    return (
        <Grid container spacing={2} sx={{mt: 1}}>
            {previewImages.length > 0 &&
                previewImages.map((element) => (
                    <Grid xs={12} sm={6} md={4} key={element.id}>
                        <Stack direction='row' justifyContent={'center'} alignItems={'center'}
                            id={element.id}
                            draggable
                            onDragOver={(e) => handleOver(e)}
                            onDragStart={(e) => handleDrag(e)}
                            onDrop={(e) => handleDrop(e)}
                            sx = {{
                                position: 'relative',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 8,
                                overflow: 'hidden',
                                '&:hover': {
                                    borderColor: 'primary.500',
                                    cursor: 'pointer',
                                },
                                '& img': {
                                    flex: 1,
                                    maxWidth: 1,
                                    minHeight: '80px',
                                    maxHeight: '300px',
                                }
                            }}
                        >
                            {element.isEdit ? 
                                <img src={helper.getImageUrl(element.uploadedFileName)} alt="Car Image"/>
                                :<img src={element.prevURL} alt={element.file.name}/>
                            }
                            {pageType == 2 && 
                                <IconButton variant="soft" size="sm" color='danger' onClick={() => deleteImage(element.id)}
                                    sx = {{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        transition: 'all 0.1s ease-out',
                                    }}
                                >
                                    <DeleteOutlineIcon/>
                                </IconButton>
                            }
                            {!element.isUploaded &&
                                <Spinner animation="border" role="status" variant="danger" style={{ position: 'absolute' }}>
                                    <span className="visually-hidden">Uploading...</span>
                                </Spinner>
                            }
                        </Stack>
                    </Grid>
                ))}
        </Grid>
    )
}

export default Preview;