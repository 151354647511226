import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FaKey, FaLock, FaUser, FaRegEnvelope, FaGoogle, FaFacebook, FaApple } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { login } from "../../store/actions/auth";
import { clearMessage } from "../../store/actions/message";
import "./style.css";


import { getGoogleUrl } from "../../utils/getGoogleUrl";
import { Link as MuiLink } from '@mui/material';
import * as constants from "../../utils/constants";

const Login = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isEmailSignin, setIsEmailSignin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const location = useLocation();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn]);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const isValidate = () => {
    if (!email) {
      setError('The email is required!');
      return false;
    } else if (!isEmail(email)) {
      setError('The email is not valid.');
      return false;
    }

    if (!password) {
      setError('The password is required!');
      return false;
    } else if (password.length < 6 || password.length > 40) {
      setError('The password must be between 6 and 40 characters.');
      return false;
    }

    if (recaptchaValue === null) {
      setError('ReCaptcha must be valid.');
      return false;
    }

    setError('');
    return true;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (!isValidate()) return;

    dispatch(login(email, password))
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
  }

  return (
    <section className="gauto-login-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="login-box">
              <div className="login-page-heading">
                <FaKey />
                <h3>{t("login_page.singin")}</h3>
              </div>
              {isEmailSignin ? (
                <form onSubmit={SubmitHandler}>
                  <div className="account-form-group">
                    <input
                      type="text"
                      placeholder={t("login_page.user_email")}
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                    />
                    <FaUser />
                  </div>
                  <div className="account-form-group">
                    <input
                      type="password"
                      placeholder={t("login_page.password")}
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                    />
                    <FaLock />
                  </div>
                  <div className="remember-row">
                    <p className="lost-pass">
                      <Link to="/" onClick={onClick}>
                        {t("login_page.f_password")}
                      </Link>
                    </p>
                    <p className="checkbox remember">
                      <input
                        className="checkbox-spin"
                        type="checkbox"
                        id="Freelance"
                      />
                      <label htmlFor="Freelance">
                        <span />
                        {t("login_page.keep")}
                      </label>
                    </p>
                  </div>
                  <ReCAPTCHA
                    sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChangeReCaptcha}
                    className="google-recaptcha"
                  />
                  {error && <Alert variant='danger'>{error}</Alert>}
                  <p>
                    <button type="submit" className="gauto-theme-btn">
                      {t("login_page.btn")}
                    </button>
                  </p>
                  {message && <Alert variant='danger'>{message}</Alert>}
                </form>
              ) : (
                <>
                  <form>
                    <p className="social-auth">
                      <MuiLink href={getGoogleUrl(from)} underline="none">
                        <FaGoogle size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_google")}
                      </MuiLink>
                    </p>
                    <p>
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }}>
                        <FaFacebook size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_facebook")}
                      </button>
                    </p>
                    <p>
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }}>
                        <FaApple size={'1.4rem'} />&nbsp;&nbsp;{t("register_page.continue_with_apple")}
                      </button>
                    </p>
                    <p>
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }} onClick={() => setIsEmailSignin(true)}>
                        <FaRegEnvelope size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_email")}
                      </button>
                    </p>
                  </form>
                  <div className="login-sign-up">
                    <Link to="/register">{t("login_page.need_account")}</Link>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
