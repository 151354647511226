import axios from "axios";
import * as constants from "../utils/constants";

const API_URL = constants.REACT_APP_API_BASE_URL + "auth/";

const register = (firstname, lastname, email, password) => {
    return axios.post(API_URL + "signup", {
        firstname,
        lastname,
        email,
        password,
    });
};

const login = (email, password) => {
    return axios.post(API_URL + "signin", {
            email,
            password
        })
        
};

const forgotPassword = (email) => {
    return axios.post(API_URL + "forgot-password", {
            email
        })
        
};

const reset = (email,hash,password) => {
    return axios.post(API_URL + "reset", {
            email,hash,password
        })
        
};

const socialLogin = (idToken) => {
    return axios.post(API_URL + "social-login", { idToken })
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    register,
    login,
    logout,
    socialLogin,
    forgotPassword,
    reset,
};