import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import CarAdd from "../../../components/CarAdd";
import Footer from "../../../components/Footer";

const CarAddPage = () => {

  return (
    <div className="flex-container">
      <Header />
      <CarAdd />
      <Footer />
    </div>
  );
};
export default CarAddPage;
