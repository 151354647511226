import Carousel from 'react-bootstrap/Carousel';
import * as helper from '../../utils/helper';
import ImageComponent from './ImageComponent';
import './style.scss'

export default function CustomCarousel({photos}) {
  return (
    <Carousel>
        {photos.map((item) => (
            <Carousel.Item key={item}>
                <ImageComponent src={helper.getImageUrl(item)}/>
            </Carousel.Item>
        ))}
    </Carousel>
  );
}
