import { Button, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams,useLocation  } from "react-router-dom";
import userService from "../../services/user.service";
import * as helper from '../../utils/helper';
import ProfileEdit from "./ProfileEdit";

const initialProfile = { phone: '', email: '', license_number: '', license_country: '', license_state: '', dob: helper.getDateStringWithYear(-25), expiration: helper.getDateStringWithYear(1),
                         first_name: '', last_name: '', street: '', city: '', zip: '', country: '', license_front: '', license_back: '',role:''};

const Profile = () => {
    // const {uerId,setUserId} = useState('');
    const { user } = useSelector(state => state.auth);
    const [ data, setData ] = useState(initialProfile);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const carId = searchParams.get('carId');
    const userIdGet = searchParams.get('userId');
    const fd = searchParams.get('fd');
    const fa = searchParams.get('fa');
    const td = searchParams.get('td');
    const ta = searchParams.get('ta');
    useEffect(() => {
        if(!user){
            navigate("/");
            return;
        }
        
        (async () => {
            console.log('id:'+userId?userId:userIdGet);

            let res = await userService.getProfile(userId?userId:userIdGet)

            console.log("Get profile : ", res.data);
            if(res.data.success){
                setData({...initialProfile, ...res.data.data});
            }
        })();
    }, [])

    return (
        <Stack flex={1} direction='row' justifyContent={'center'}>
            <Stack sx={{width: '100%', maxWidth: 1200, px: {xs: 2, sm: 8, md: 4}, py: 4}}>
                <Stack mb={3} direction='row' justifyContent={'space-between'}>
                    <Typography level='h2'>Profile</Typography>
                    {/* <Button variant='soft' color='success' component={Link} to={`/profileview/${userId}`} target='_blank'>View Public Profile</Button> */}
                    <Button variant='soft' color='success' component={Link} to={`/profileview/${userId}`}>View Public Profile</Button>
                </Stack>
                <ProfileEdit data={data} setData={setData} userId={userId}/>
            </Stack>
        </Stack>
    );
};

export default Profile;
