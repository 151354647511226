import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Stack from '@mui/joy/Stack';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch, } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CarService from '../../services/car.service';
import CarCalendar from "../Calendar/CarCalendar";
import CarBasicDetail from "./CarBasicDetail";
import Preview from "./Preview";
import Uploader from "./Uploader";
import { setCarInfoRedux } from "../../store/actions/car";
// pageType: 1 - add first step (edit all)  2-edit(not editable vin) 3- (when add. not edit all except description) 4- only view

import { requiredFields } from "../../utils/helper";
import { Button } from '@mui/joy';
import { showToast } from '../../utils/toasts';

const tabs = [
              {id: 'photos', label: 'Photos'},
              {id: 'details', label: 'Details'},
              {id: 'calendar', label: 'Calendar'},
            ]

const initialCarInfo = {
  vin: '',
  car_type: '',
  interior_color: '',
  exterior_color: '',
  car_name: '',
  year: '',
  model: '',
  manufacturer: '',
  trim: '',
  transmission: '',
  plate_state: '',
  seats_number: '',
  doors_number: '',
  fuel_type: '',
  gas_grade: '',
  city_mpg: '',
  hwy_mpg: '',
  description: '',
  features: [],
  addresses: []
};

function getTabValue(tab) {
    for(let ind in tabs){
        if(tabs[ind].id === tab) return ind;
    }
    return 0;
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CarEdit = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const [isSaving, setIsSaving] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [tab, setTab] = useState(0);
  const [carInfo, setCarInfo] = useState(initialCarInfo);
  const [error, setError] = useState({});
  const [pageType, setPageType] = useState(4);
  const { carId } = useParams();
  const query = useQuery();
  const queryTab = query.get("tab");
  const dispatch = useDispatch();
  useEffect(() => {
      (async () => {
        let res = await CarService.getCarInfo(carId)
        console.log("Get car info : ", res.data);
        if(res.data.success){
            setCarInfo(res.data.data);
            dispatch(setCarInfoRedux(res.data.data));
            generate_previews(res.data.data.photos)
        }
    })();
  }, [carId, dispatch])

  useEffect(() => {
    setTab(getTabValue(queryTab));
  }, [queryTab])

  useEffect(() => {
    if(user && carInfo && carInfo.id){
      if(carInfo.user_id === user.id){
        setPageType(2)
      }else{
        navigate(`/car-detail/${carInfo.id}`)
      }
    }
  }, [user, carInfo])

  const generate_previews = (photos) => {
    let temp = [];
    photos.forEach((photo) => {
        temp.push({
            id: new Date().getTime() + photo,
            isUploaded: true,
            uploadedFileName: photo,
            isEdit: true,
        })
    })
    setPreviewImages(temp);
  }

  const checkValid = () => {
    let temp = {...error};
    let valid = true;
    for(const field of requiredFields){
      if(carInfo[field] == ''){
        temp[field] = 'Please input this field'
        valid = false;
      }else{
        temp[field] = ''
      }
    }
    setError(temp);
    return valid;
  }

  const handleSave = async () => {
    if(!checkValid()) return;
    setIsSaving(true);
    let photos = [];
    previewImages.forEach((img) => {
        photos.push(img.uploadedFileName);
    });
    let data = { ...carInfo, photos: photos}

    let res = await CarService.saveCarInfo(data)
    console.log("response : ", res.data);
    if(res.data.success){
        showToast('success', "Saved successfully");
    }else{
    }
    setIsSaving(false);
  }

  return (
    <Stack direction='row' justifyContent='center' sx={{flex: 1, px:1, py: 2}}>
      <Stack sx={{maxWidth: 1200, width: '100%'}}>

        <Tabs aria-label="tabs" defaultValue={0} value={Number(tab)} sx={{bgcolor: 'background.body'}}>
          <TabList
            disableUnderline
            sx={{
              p: 0.5,
              gap: 0.5,
              borderRadius: 'xl',
              bgcolor: 'background.level1',
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
            }}
          >
              <Tab disableIndicator component={Link} to={`/car-edit/${carInfo.id}?tab=photos`}>
                <ListItemDecorator>
                  <ImageOutlinedIcon />
                </ListItemDecorator>
                Photos
              </Tab>
              <Tab disableIndicator component={Link} to={`/car-edit/${carInfo.id}?tab=details`}>
                  <ListItemDecorator>
                    <ChecklistRtlOutlinedIcon />
                      Details
                  </ListItemDecorator>
              </Tab>
              <Tab disableIndicator component={Link} to={`/car-edit/${carInfo.id}?tab=calendar`}>
                <ListItemDecorator>
                  <EditCalendarOutlinedIcon />
                </ListItemDecorator>
                Calendar
              </Tab>
          </TabList>
          {tab != 2 && <Stack direction='row' justifyContent={'flex-end'} mt={2}>
            <Button variant='solid' onClick={handleSave} disabled={isSaving}>Save</Button>
          </Stack>}
          <TabPanel value={0} sx={{pt: 1, px: {xs: 1, sm: 2} }}>
            <div>
                {pageType == 2 && <Uploader setPreviewImages={setPreviewImages} previewImages={previewImages} />}
                <Preview previewImages={previewImages} setPreviewImages={setPreviewImages} pageType={pageType}/>
            </div>
          </TabPanel>
          <TabPanel value={1} sx={{pt: 1, px: {xs: 1, sm: 2} }}>
            <CarBasicDetail carInfo={carInfo} activeNumber={0} setCarInfo={setCarInfo} error={error} setError={setError} pageType={pageType}/>
          </TabPanel>
          <TabPanel value={2} sx={{pt: 5, px: {xs: 1, sm: 2} }}>
            <CarCalendar carInfo={carInfo} setCarInfo={setCarInfo} pageType={pageType}/>
          </TabPanel>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default CarEdit;
