import React from "react";

import Booking from "../../../components/Book/Booking";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";

const BookPage = () => {

  return (
    <div className="flex-container">
      <Header />
      <Booking />
      <Footer />
    </div>
  );
};
export default BookPage;
