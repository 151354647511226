import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FlagIcon from '@mui/icons-material/Flag';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { Link } from "react-router-dom";
import * as helper from '../../utils/helper';
import Rating from './Rating';

export default function CarListCard({car, selected=false, ...props}) {
    const { rareFind = true, direction='column' } = props;
    const [isLiked, setIsLiked] = React.useState(false);
    const onLike = (e) => {
        e.stopPropagation();
        setIsLiked((prev) => !prev)
    }
    return (
    <Card
        variant="outlined"
        orientation="horizontal"
        sx={[{
            bgcolor: 'neutral.softBg',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'primary.500',
                bgcolor: 'primary.100'
            },
        }, selected && {
            borderColor: 'primary.500',
            bgcolor: 'primary.100'
        }]}
        onClick={()=>props.onSelect(car.car)}
    >
        <CardOverflow
            sx={{
            mr: { xs: 'var(--CardOverflow-offset)', sm: 0 },
            mb: { xs: 0, sm: 'var(--CardOverflow-offset)' },
            '--AspectRatio-radius': {
                xs: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0',
                sm: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0 calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px))',
            },
            }}
        >
            <AspectRatio
                ratio="1.6"
                flex
                sx={{
                    minWidth: { sm: 290, md: 200, lg: 300},
                    '--AspectRatio-maxHeight': { xs: '300px', sm: '9999px' },
                }}
            >
                <img alt="" src={helper.getImageUrl(car.car.photos[0])} />
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{ position: 'absolute', top: 0, width: '100%', p: 1 }}
                >
                    {rareFind && (
                        <Chip
                            variant="soft"
                            color="success"
                            size="sm"
                        >
                            Unlimited
                        </Chip>
                    )}
                    <IconButton
                        variant="plain"
                        size="sm"
                        color={isLiked ? 'danger' : 'neutral'}
                        onClick={() => setIsLiked((prev) => !prev)}
                        sx={{
                            display: { xs: 'flex', sm: 'none' },
                            ml: 'auto',
                            borderRadius: '50%',
                            zIndex: '20',
                        }}
                    >
                        <FavoriteRoundedIcon />
                    </IconButton>
                </Stack>
            </AspectRatio>
        </CardOverflow>
        <CardContent>
            <Stack
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
            <div>
                <Link to={`/car-detail/${car.car.id}?tab=details`} target='_blank'>
                    <Typography level="title-md" 
                        sx={{
                            '&:hover': {
                                color: 'primary.500'
                            }
                        }}
                    >
                        {car.car.car_name}
                    </Typography>
                </Link>
            </div>
            <IconButton
                variant="plain"
                size="sm"
                color={isLiked ? 'danger' : 'neutral'}
                onClick={onLike}
                sx={{
                display: { xs: 'none', sm: 'flex' },
                borderRadius: '50%',
                }}
            >
                <FavoriteRoundedIcon />
            </IconButton>
            </Stack>
            <Stack
                spacing="0.25rem 1rem"
                direction="row"
                useFlexGap
                flexWrap="wrap"
                sx={{ my: 0.25 }}
                >
                <Typography level="body-xs" startDecorator={<DirectionsCarIcon />}>
                    {car.car.model}
                </Typography>
                <Typography level="body-xs" startDecorator={<SettingsSuggestIcon />}>
                    automatic
                </Typography>
            </Stack>
            <Stack
                spacing="0.25rem 1rem"
                direction="row"
                useFlexGap
                flexWrap="wrap"
                sx={{ my: 0.25 }}
                >
                {car.bFromAddress && <Typography level="body-xs" startDecorator={<FlagIcon />}>
                    Located in 50 miles of From Address
                </Typography>}
                {car.bToAddress && <Typography level="body-xs" startDecorator={<FlagIcon />}>
                    Located in 50 miles of To Address
                </Typography>}
            </Stack>
            <Stack direction="row" sx={{ mt: 'auto' }}>
                <Rating/>
                <Typography level="title-lg" sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <strong>$500</strong> <Typography level="body-md">/day</Typography>
                </Typography>
            </Stack>
        </CardContent>
    </Card>
  );
}
