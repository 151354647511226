import React, { createContext, useContext, useState } from 'react';
import * as helper from '../utils/helper';
// Create a context to hold the state and the function to update it
const SharedSearchParamContext = createContext();
const initialSearch = {fd: helper.getStringFromDateTime(new Date()), td: helper.getStringFromDateTime(new Date()), fa: "", ta: "",fr:50,tr:50};
export function SharedSearchParamProvider({ children }) {
  const [searchParamState, setSearchParamState] = useState(initialSearch); // Store state as an object

  return (
    <SharedSearchParamContext.Provider value={{ searchParamState, setSearchParamState }}>
      {children}
    </SharedSearchParamContext.Provider>
  );
}

// Custom hook to use the shared state
export function useSearchParam() {
  const context = useContext(SharedSearchParamContext);
  if (context === undefined) {
    throw new Error('useSearchParam must be used within a SharedSearchParamProvider');
  }
  return context;
}