import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    FORGOT_PASSWORD,
    RESET
} from "./types";

import AuthService from "../../services/auth.service";
import { showToast } from "../../utils/toasts"


export const register = (firstname, lastname, email, password) => (dispatch) => {
    
    return AuthService.register(firstname, lastname, email, password).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });

            // dispatch({
            //     type: SET_MESSAGE,
            //     payload: response.data.message,
            // });
    
            let data = response.data;
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
            });

            //return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            showToast("error", message);
        }
    );
};

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                let data = response.data;
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
            });
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            showToast("error", message);
        }
    );
};

export const forgotPassword = (email) => (dispatch) => {
    return AuthService.forgotPassword(email).then((response) => {
                
                const message = response.data.message;
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                showToast("success", message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            showToast("error", message);
        }
    );
};

export const reset = (email,hash,password) => (dispatch) => {
    return AuthService.reset(email,hash,password).then((response) => {
                
                const message = response.data.message;
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                showToast("success", message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            showToast("error", message);
        }
    );
};


export const socialLogin = (idToken) => (dispatch) => {
    return AuthService.socialLogin(idToken).then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                let data = response.data;
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
            });
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            showToast("error", message);
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};