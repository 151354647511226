import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "../UserPages/HomePage";
import FleetPage from "../UserPages/FleetPage";
import CarAddPage from "../UserPages/CarAddPage";
import CarEditPage from "../UserPages/CarEditPage";
import CarDetailViewPage from "../UserPages/CarDetailViewPage";

import CarListingPage from "../UserPages/CarListingPage";
import ErrorPage from "../UserPages/ErrorPage";
import LoginPage from "../UserPages/LoginPage";
import ForgotPasswordPage from "../UserPages/ForgotPasswordPage";
import ResetPage from "../UserPages/ResetPage";
import RegisterPage from "../UserPages/RegisterPage";
import ProfilePage from "../UserPages/ProfilePage";
import ProfileViewPage from "../UserPages/ProfileViewPage";
import BookPage from "../UserPages/BookPage";
import BookViewPage from "../UserPages/BookPage/BookViewPage";
import BookingPage from "../UserPages/BookPage/BookingPage";

import DashboardPage from "../AdminPages/DashboardPage";
import UsersPage from "../AdminPages/UsersPage";
import OrderDashboard from "../AdminPages/OrderDashboard";
import UserDashboard from "../AdminPages/UserDashboard/UserDashboard";
import UserProfileDashboard from "../AdminPages/UserDashboard/UserProfileDashboard";
import Dashboard from "../AdminPages/Dashboard";

const AllRoute = () => {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route exact path="/fleet" element={<FleetPage />} />
          <Route path="/forgotpassword/:userEmail" element={<ForgotPasswordPage />} />
          <Route path="/reset" element={<ResetPage />} />
          <Route exact path="/fleet/:userId" element={<FleetPage />} />
          <Route path="/car-add" element={<CarAddPage />} />
          <Route exact path="/car-edit/:carId" element={<CarEditPage/>} />
          <Route exact path="/car-detail/:carId" element={<CarDetailViewPage/>} />

          <Route path="/car-listing" element={<CarListingPage />} />
          <Route exact path="/profile/:userId" element={<ProfilePage />} />
          <Route exact path="/profileview/:userId" element={<ProfileViewPage />} />
          <Route exact path="/book" element={<BookPage />} />
          <Route exact path="/book/:bookId" element={<BookViewPage />} />
          <Route exact path="/booking" element={<BookingPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          {/* <Route path="/admin/dashboard" element={<DashboardPage />} /> */}
          <Route path="/admin/users" element={<UserDashboard />} />
          <Route path="/admin/orders" element={<OrderDashboard />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/userprofile/:userId" element={<UserProfileDashboard />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
  );
};

export default AllRoute;
