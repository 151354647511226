import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Input, Sheet, Stack, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as helper from '../../utils/helper';
import AddressField from './AddressField';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

const initialSearch = {fd: helper.getStringFromDateTime(new Date(new Date(new Date().setDate(new Date().getDate() + 3)).setHours(0,0,0,0))), td: helper.getStringFromDateTime(new Date(new Date(new Date().setDate(new Date().getDate() + 7)).setHours(23,59,59,0))), fa: "", ta: "",fr:50,tr:50};
const initialFromAddress={
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  googleMapLink: ''
}
const initialToAddress={
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  googleMapLink: ''
}
const FindCar = () => {
  const navigate = useNavigate();
  const [ search, setSearch ] = useState({...initialSearch})
  const [ fromAddress, setFromAddress ] = useState({...initialFromAddress})
  const [ toAddress, setToAddress ] = useState({...initialToAddress})

  const onSearchClick = (e) => {
    console.log("hhhhh"+JSON.stringify(search))
    let queryString = helper.buildQueryString(search);
    navigate(`/car-listing?${queryString}`);
  };

  const onSearchChange = (e) => {
    setSearch({...search, [e.target.name]: e.target.value});
  }

  const onFromAddressHolder = (address) => {
    setFromAddress(address);
    setToAddress(address);
    setSearch(prev => ({...prev, fa: (Object.keys(address).length === 0 && address.constructor === Object) ? "" :JSON.stringify(address)}));
    setSearch(prev => ({...prev, ta:  (Object.keys(address).length === 0 && address.constructor === Object) ? "" :JSON.stringify(address)}));
  }

  const onToAddressHolder = (address) => {
    setToAddress(address);
    setSearch(prev => ({...prev, ta:  (Object.keys(address).length === 0 && address.constructor === Object) ? "" :JSON.stringify(address)}));
  }

  const handleChangeFromAddress = (event,newValue,) => {
    setSearch(prev => ({...prev, fr: newValue}));
  };

  const handleChangeToAddress = (event,newValue,) => {
    setSearch(prev => ({...prev, tr: newValue}));
  };

  return (
    <Box sx={{
        backgroundImage: 'url("https://www.montway.com/app/uploads/Blog_2023/Best-road-trips-in-US_1294_header_final.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        pt: 2,
        flex: 1,
        height: 600
      }}
    >
      <Stack direction='row' justifyContent='center'>
        <Sheet sx={{
                  borderRadius: 'var(--joy-radius-sm)', px: 2, py: 1,
                  width: '100%',
                  maxWidth: 1200,
               }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} lg={3.2}>
              {/* <Input id="autocomplete" className="input-field" ref="input" type="text" size='sm' variant="soft" placeholder={""} name='fa' value={search.fa} onChange={onSearchChange}/> */}
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Stack direction="column" alignItems="flex-start">
                  <Typography level="body-xs">From Address</Typography>
                  <AddressField
                    // onSearchChange={onSearchChange}
                    // search={search.fa}
                    address={fromAddress}
                    onPlaceHolder={onFromAddressHolder}
                    name="fa"
                  />
                </Stack>
                <Stack direction="column" alignItems="flex-start" style={{width: '100px',marginLeft:'10px'}}>
                  <Typography level="body-xs">Mile</Typography>
                  <Select defaultValue="50" name='radiusFromAddressT' onChange={handleChangeFromAddress} >
                    <Option value="50">50</Option>
                    <Option value="30">30</Option>
                    <Option value="20">20</Option>
                    <Option value="10">10</Option>
                  </Select>
                </Stack>
              </div>
            </Grid>
            <Grid xs={12} sm={6} lg={3.2}>
              {/* <Input size='sm' variant="soft" placeholder={""} name='ta' value={search.ta} onChange={onSearchChange}/> */}
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Stack direction="column" alignItems="flex-start">
                  <Typography level="body-xs">To Address</Typography>
                  <AddressField
                    // onSearchChange={onSearchChange}
                    // search={search.ta}
                    address={toAddress}
                    onPlaceHolder={onToAddressHolder}
                    name="ta"
                  />
                </Stack>
                <Stack direction="column" alignItems="flex-start"  style={{width: '100px',marginLeft:'10px'}}>
                  <Typography level="body-xs">Mile</Typography>
                  <Select defaultValue="50"  name='radiusToAddressT' onChange={handleChangeToAddress}>
                    <Option value="50">50</Option>
                    <Option value="30">30</Option>
                    <Option value="20">20</Option>
                    <Option value="10">10</Option>
                  </Select>
                </Stack>
              </div>
            </Grid>
            <Grid xs={12} sm={6} lg={2.3}>
              <Typography level="body-xs">From</Typography>
              <Input type="datetime-local" variant="soft"  name='fd'  value={search.fd} max={search.td ? search.td : undefined} onChange={onSearchChange}/>
            </Grid>
            <Grid xs={12} sm={6} lg={2.3}>
              <Typography level="body-xs">Until</Typography>
              <Input type="datetime-local" variant="soft" name='td' value={search.td} min={search.fd ? search.fd : undefined} onChange={onSearchChange} />
            </Grid>
            <Grid xs={12} sm={12} lg={1}>
              <IconButton variant="outlined" color='primary' sx={{mt: '19px', width: '100%'}} onClick={onSearchClick}>
                <SearchIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Sheet>
      </Stack>
    </Box>
  );
};

export default FindCar;
