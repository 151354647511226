import { useMediaQuery } from '@mui/material';
import React, { useState,useEffect } from "react";

import { Box, Stack, Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/joy";
import CustomCarousel from '../CustomCarousel';
import Star from '@mui/icons-material/Star';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import Door from '../Icons/Door';
import CheckIcon from '@mui/icons-material/Check';
import * as helper from '../../utils/helper';
import CarCalendar from '../Calendar/CarCalendar';
import { useSelector } from 'react-redux';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link } from 'react-router-dom';
import BookComponent from './BookComponent';
import CarService from '../../services/car.service';

const initialDetails = { vin: "", car_type: "Car", interior_color: '', exterior_color: '', car_name: "", make: "", year: "", model: "", manufacturer: "", trim: "", transmission: "", engine: "", plate_number: "",
                    plate_state: "FL", seats_number: 5, doors_number: 4, fuel_type: 'Gas', gas_grade: 87, city_mpg: '', hwy_mpg: '', description: ''}

var defaultCarInfo = {...initialDetails, features: []};

const CarDetailViewLayout = ({car, ...props}) => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const [showCalendar, setShowCalendar] = useState(false);
  const { user } = useSelector(state => state.auth);
  const isOwner = user && car && car.user_id === user.id;
  const [carInfo, setCarInfo] = useState(defaultCarInfo);
  const [selectDate, setSelectDate] = useState(new Date());
  useEffect(() => {
    (async () => {
      let res = await CarService.getCarInfo(car.id)
    //   console.log("Get car info : ", res.data);
      if(res.data.success){
          setCarInfo(res.data.data);
      }
  })();
}, [])
  return (
      <Stack direction='column'>
        <CustomCarousel photos={car ? car.photos : []}/>
        <Stack direction='column' gap={2} sx={{px: 2, py: 5, maxWidth: 800, mx: 'auto', position: 'relative'}}>
            {isOwner && <Button component={Link} to={`/car-edit/${car.id}?tab=details`} variant='outlined' color='danger' sx={{position: 'absolute', top: 8, right: 8}} startDecorator={<ModeEditOutlineOutlinedIcon/>}> Edit Car</Button>}
            <Typography level='h3'>{car.car_name}</Typography>
            <Typography level="body-xs">
                Vin: {car.vin}
            </Typography>
            <Typography
                level="title-md"
                startDecorator={<Star sx={{ color: 'warning.400' }}/>}>
                    4.0 (60 Trips)
            </Typography>
            <Typography level='title-sm'>
                Base
            </Typography>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={6}>
                    <Typography level="body-sm" startDecorator={<DirectionsCarIcon />}>
                        {car.model}
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography level="body-sm" startDecorator={<SettingsSuggestIcon />}>
                        {car.fuel_type}
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography level="body-sm" startDecorator={<ElectricCarIcon />}>
                        {car.model}
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography level="body-sm" startDecorator={<AirlineSeatLegroomReducedIcon />}>
                        {car.seats_number}
                    </Typography>
                </Grid>
            </Grid>

            <Typography level='title-sm'>
                Description
            </Typography>
            <Typography level='body-sm'>
                {car.description}
            </Typography>
            <Typography level='title-sm'>
                Features
            </Typography>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                {car.features.map((item) => (
                    <Grid xs={6} key={item}>
                        <Typography level="body-sm" startDecorator={<CheckIcon />}>
                            {helper.getFeatureLabel(item)}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            {!isOwner &&
            <Stack gap={2} sx={{mt: 3}}>
                <Button variant='outlined' onClick={()=>setShowCalendar((prev)=>!prev)}>
                    {showCalendar ? 'Hide Calendar & Book' : 'Show Calendar & Book'}
                </Button>
                <Box sx={{display: showCalendar ? 'block' : 'none'}}>
                    <Stack direction={{xs: 'column', sm: 'row' }}>
                        <CarCalendar carInfo={carInfo} pageType={4} setSelectDate={setSelectDate}/>
                        <Box mt={{xs: 1, sm: 6}}>
                            <BookComponent carId={car.id} userId={car.user_id} car={carInfo} selectDate={selectDate}/>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            }
        </Stack>
      </Stack>
  );
};

export default CarDetailViewLayout;
