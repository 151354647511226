import { toast } from 'react-toastify'

export const showToast = (type, text) => {
    if(type == 'success'){
        toast.success(text, {})
    }else if(type == 'info'){
        toast.info(text, {})
    }else if(type == 'error'){
        toast.error(text, {})
    }else if(type == 'warn'){
        toast.warn(text, {})
    }}
