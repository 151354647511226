import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { FaKey, FaLock, FaUser, FaRegEnvelope, FaGoogle, FaFacebook, FaApple } from "react-icons/fa";
import { isEmail } from "validator";
import "./style.css";

import { register } from "../../store/actions/auth";
import { clearMessage } from "../../store/actions/message";
import { getGoogleUrl } from "../../utils/getGoogleUrl";
import { Link as MuiLink } from '@mui/material';

const Register = () => {
  const { t } = useTranslation();
  const [isEmailSignup, setIsEmailSignup] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState('');
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const location = useLocation();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeCPassword = (e) => {
    const cpassword = e.target.value;
    setCPassword(cpassword);
  };

  const isValidate = () => {
    if (!firstname) {
      setError('The first name is required!');
      return false;
    } else if (firstname.length < 3 || firstname.length > 20) {
      setError('The first name must be between 3 and 20 characters.');
      return false;
    }

    if (!lastname) {
      setError('The last name is required!');
      return false;
    } else if (lastname.length < 3 || lastname.length > 20) {
      setError('The last name must be between 3 and 20 characters.');
      return false;
    }

    if (!email) {
      setError('The email is required!');
      return false;
    } else if (!isEmail(email)) {
      setError('The email is not valid.');
      return false;
    }

    if (!password) {
      setError('The password is required!');
      return false;
    } else if (password.length < 6 || password.length > 40) {
      setError('The password must be between 6 and 40 characters.');
      return false;
    }

    if (!cpassword) {
      setError('The confirm password is required!');
      return false;
    } else if (password !== cpassword) {
      setError('The confirm password is incorrect.');
      return false;
    }

    setError('');
    return true;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (!isValidate()) return;

    setSuccessful(false);

    dispatch(register(firstname, lastname, email, password));
  };

  return (
    <section className="gauto-login-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="login-box">
              <div className="login-page-heading">
                <FaKey />
                <h3>{t("register_page.singup")}</h3>
              </div>
              {isEmailSignup ? (
                <form onSubmit={SubmitHandler}>
                  {!successful && (
                    <>
                      <div className="account-form-group">
                        <input
                          type="text"
                          name="firstname"
                          value={firstname}
                          onChange={onChangeFirstname}
                          placeholder={t("register_page.first_name")}
                        />
                        <FaUser />
                      </div>
                      <div className="account-form-group">
                        <input
                          type="text"
                          name="lastname"
                          value={lastname}
                          onChange={onChangeLastname}
                          placeholder={t("register_page.last_name")}
                        />
                        <FaUser />
                      </div>
                      <div className="account-form-group">
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={onChangeEmail}
                          placeholder={t("register_page.email")}
                        />
                        <FaRegEnvelope />
                      </div>
                      <div className="account-form-group">
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={onChangePassword}
                          placeholder={t("register_page.password")}
                        />
                        <FaLock />
                      </div>
                      <div className="account-form-group">
                        <input
                          type="password"
                          name="password"
                          value={cpassword}
                          onChange={onChangeCPassword}
                          placeholder={t("register_page.c_password")}
                        />
                        <FaLock />
                      </div>
                      {error && <Alert variant='danger'>{error}</Alert>}
                      <p>
                        <button type="submit" className="gauto-theme-btn">
                          {t("register_page.register_now")}
                        </button>
                      </p>
                    </>
                  )}

                  {message && (
                    <div>
                      <Alert variant={successful ? 'success' : 'danger'}>{message}</Alert>
                      {successful &&
                        <div className="login-sign-up">
                          <Link to="/login">{t("register_page.go_to_login")}</Link>
                        </div>
                      }
                    </div>
                  )}
                </form>
              ) : (
                <>
                  <form>
                    <p className="social-auth">
                      <MuiLink href={getGoogleUrl(from)} underline="none">
                        <FaGoogle size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_google")}
                      </MuiLink>
                    </p>
                    <p className="social-auth">
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }}>
                        <FaFacebook size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_facebook")}
                      </button>
                    </p>
                    <p className="social-auth">
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }}>
                        <FaApple size={'1.4rem'} />&nbsp;&nbsp;{t("register_page.continue_with_apple")}
                      </button>
                    </p>
                    <p className="social-auth">
                      <button type="submit" className="gauto-theme-btn" style={{ width: '100%' }} onClick={() => setIsEmailSignup(true)}>
                        <FaRegEnvelope size={'1.2rem'} />&nbsp;&nbsp;{t("register_page.continue_with_email")}
                      </button>
                    </p>
                  </form>
                  <div className="login-sign-up">
                    <Link to="/login">{t("register_page.have_account")}</Link>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
