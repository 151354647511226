import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';
// import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { isEmail } from "validator";

import { setModalSign } from '../../store/actions/modal';

import { login } from "../../store/actions/auth";
import GoogleButton from './GoogleButton';

export default function LoginLayout() {
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("center");
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState({email: '', pwd: ''});
  const [email, setEmail] = useState('johndoe@email.com');
  const [error, setError] = useState({});

  // const [recaptchaValue, setRecaptchaValue] = useState(null);
  
  const handleChange = (e) => {
    let name = e.target.name;
    let v = e.target.value;
    setValue({...value, [name]: v});
    if(name=="email")
      setEmail(v)
    if(isClicked){
      setError({...error, [name]: isValid(name, v)});
    }
  }

  const isValid = (name, v) => {
    let errorMsg = '';
    if(name === 'email'){
      if (!v) {
        errorMsg = "The email is required!";
      } else if (!isEmail(v)) {
        errorMsg = "The email is not valid.";
      }
    }else if(name === 'pwd'){
      if (!v) {
        errorMsg = 'The password is required!';
      }
    }
    return errorMsg
  }

  const isValidate = () => {
    let emailError = isValid('email', value.email);
    let pwdError = isValid('pwd', value.pwd);
    setError({email: emailError, pwd: pwdError});
    return (emailError === '' && pwdError === '') ;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!isValidate()) return;
    dispatch(login(value.email, value.pwd))
  };

  return (
      <Stack direction={'column'}
        sx={{
          overflow: 'auto',
          mx: 'calc(-1 * var(--ModalDialog-padding))',
          px: 'var(--ModalDialog-padding)',
        }}
      >
        <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Log in</Typography>
                <Typography level="body-sm">
                  Don't have an account?{' '}
                  <Link level="title-sm" onClick={(e)=>dispatch(setModalSign("register"))}>
                    Sign up!
                  </Link>
                </Typography>
              </Stack>
              <GoogleButton/>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                  '--Divider-lineColor': {
                    xs: '#FFF',
                    md: 'var(--joy-palette-divider)',
                  },
                },
              })}
            >
              or
            </Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form>
                <FormControl error={!!error.email}>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" value={value.email} onChange={handleChange} placeholder={email} />
                  <FormHelperText>{error.email}</FormHelperText>
                </FormControl>
                <FormControl error={!!error.pwd}>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="pwd" value={value.pwd} onChange={handleChange}/>
                  <FormHelperText>{error.pwd}</FormHelperText>
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox size="sm" label="Remember me" name="persistent" />
                    <Link level="title-sm" href={`/forgotpassword/${email}`}>
                      Forgot your password?
                    </Link>
                  </Box>
                  {/* <ReCAPTCHA
                    sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value)=>setRecaptchaValue(value)}
                    className="google-recaptcha"
                  /> */}
                  <Button fullWidth onClick={SubmitHandler}>
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
      </Stack>
  );
}