import axios from "axios";
import authHeader from "./auth-header";
import * as constants from "../utils/constants";

const API_URL = constants.REACT_APP_API_BASE_URL + "user/";

// const getPublicContent = () => {
//     return axios.get(API_URL + "all");
// };

const getUsers = (data) => {
    return axios.post(API_URL + "all",data, { headers: authHeader() });
};

const getProfile = (userId) => {
    return axios.post(API_URL + "get-profile", {user_id: userId}, { headers: authHeader() });
};

const deleteUser = (userId) => {
    return axios.post(API_URL + "delete-user", {user_id: userId}, { headers: authHeader() });
};

const saveProfile = (data) => {
    return axios.post(API_URL + "save-profile", data, { headers: authHeader() });
};

const getModeratorBoard = () => {
    return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
    return axios.get(API_URL + "admin", { headers: authHeader() });
};

export default {
    getUsers,
    getModeratorBoard,
    getAdminBoard,
    getProfile,
    saveProfile,
    deleteUser
};