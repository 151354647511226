import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import * as helper from '../../utils/helper';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import { IconButton, Input, Typography , Stack} from '@mui/joy';
import useOutsideClick from "../../utils/useOustsideclick";
import "./topfindbarstyle.scss";
import AddressField from '../findcar/AddressField';
import { useSearchParam } from '../../hooks/useSearchParam';
const initialFromAddress={
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  googleMapLink: ''
}
const initialToAddress={
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip_code: '',
  googleMapLink: ''
}
const TopFindBar = (props) => {
  const searchRef = useRef(null);
  const [isSearchVisible, setSearchVisible] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [ fromAddress, setFromAddress ] = useState({...initialFromAddress})
  const [ toAddress, setToAddress ] = useState({...initialToAddress})
  const [ fromRadius, setFromRadius ] = useState(50)
  const [ toRadius, setToRadius ] = useState(50)
  const { searchParamState, setSearchParamState } = useSearchParam();

  const handleOutsideClick = () => {
    setSearchVisible(false);
  };

  useOutsideClick(searchRef, handleOutsideClick);
  
  const onSearchChange = (e) => {
    if(props.onSearchChange){
      props.onSearchChange(e);
    }
  }

  const onSearchClick = (e) => {
    setSearchVisible(false);
    if(props.onSearch){
      props.onSearch();
    }
  }

  useEffect(() => {
    if(isSmallScreen){
      setSearchVisible(false);
    }
  }, [isSmallScreen])

 
  useEffect(() => {
    console.log("h555"+JSON.stringify(searchParamState))
    if(searchParamState.fa != "")
      setFromAddress(JSON.parse(searchParamState.fa));
    if(searchParamState.ta != "")
      setToAddress(JSON.parse(searchParamState.ta));
    if(searchParamState.fr != null)
      setFromRadius(searchParamState.fr);
    if(searchParamState.tr != null)
      setToRadius(searchParamState.tr);
  }, [searchParamState.fa, searchParamState.ta,searchParamState.fr,searchParamState.tr])


  const onFromAddressHolder = (address) => {
    
    setFromAddress(address);
    props.setSearch(prev => ({...prev, fa: (Object.keys(address).length === 0 && address.constructor === Object) ? "" : JSON.stringify(address)}));

    setToAddress(address);
    props.setSearch(prev => ({...prev, ta:  (Object.keys(address).length === 0 && address.constructor === Object) ? "" :JSON.stringify(address)}));
  }

  const onToAddressHolder = (address) => {
    
    setToAddress(address);
    props.setSearch(prev => ({...prev, ta:  (Object.keys(address).length === 0 && address.constructor === Object) ? "" :JSON.stringify(address)}));
  }

  const handleChangeFromAddress = (event,newValue,) => {
    props.setSearch(prev => ({...prev, fr: newValue}));
  };

  const handleChangeToAddress = (event,newValue,) => {
    props.setSearch(prev => ({...prev, tr: newValue}));
  };

  return (
    <div className="topfindbar">
      {isSmallScreen && <div className={`find-label ${!isSearchVisible ? '' : 'opened'}`} onClick={()=>setSearchVisible(true)}>
        <div className="btn-angle"><FaAngleDown/></div>
        <Typography>
          {searchParamState.fa  && <span style={{marginRight: '20px'}}>from:<span>{searchParamState.fa}</span></span>}
          {searchParamState.ta && <span>to: <span>{searchParamState.ta}</span></span>}
        </Typography>
        <Typography><span>{helper.getShortDateTimeFromDate(searchParamState.fd)}</span> - <span>{helper.getShortDateTimeFromDate(searchParamState.td)}</span></Typography>
      </div>}
      <div className="back-overlay"></div>
      <div className="search-container" ref={searchRef}>
        <div style={{width: '100%'}}>
          
          {/* <Input size='sm' variant="soft" placeholder={""} name='fa' value={props.data.fa} onChange={onSearchChange}/> */}
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Stack direction="column" alignItems="flex-start" style={{width: '100%'}}>
              <Typography level="body-xs">From Address</Typography>
              <AddressField
                    // onSearchChange={onSearchChange}
                    // search={search.fa}
                    address={fromAddress}
                    onPlaceHolder={onFromAddressHolder}
                    name="fa"
                  />
            </Stack>
            <Stack direction="column" alignItems="flex-start"  style={{width: '80px',marginLeft:'10px'}}>
              <Typography level="body-xs">Mile</Typography>
              <Select value={String(fromRadius)} name='radiusFromAddress' onChange={handleChangeFromAddress}>
                <Option value="50">50</Option>
                <Option value="30">30</Option>
                <Option value="20">20</Option>
                <Option value="10">10</Option>
              </Select>
            </Stack>
          </div>
        </div>
        <div style={{width: '100%'}}>
            {/* <Input size='sm' variant="soft" placeholder={""} name='ta' value={props.data.ta} onChange={onSearchChange}/> */}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Stack direction="column" alignItems="flex-start" style={{width: '100%'}}>
                <Typography level="body-xs">To Address</Typography>
                <AddressField
                    // onSearchChange={onSearchChange}
                    // search={search.ta}
                    address={toAddress}
                    onPlaceHolder={onToAddressHolder}
                    name="ta"
                  />
              </Stack>
              <Stack direction="column" alignItems="flex-start"  style={{width: '80px', marginLeft: '10px'}}>
                <Typography level="body-xs">Mile</Typography>
                <Select value={String(toRadius)} name='radiusToAddress' onChange={handleChangeToAddress}>
                  <Option value="50">50</Option>
                  <Option value="30">30</Option>
                  <Option value="20">20</Option>
                  <Option value="10">10</Option>
                </Select>  
              </Stack>
            </div>
        </div>
        <div style={{width: '100%', minWidth: '210px'}}>
          <Typography level="body-xs">From</Typography>
          <Input type="datetime-local" variant="soft"  name='fd'  value={searchParamState.fd} max={searchParamState.td ? searchParamState.td : undefined} onChange={onSearchChange}/>
        </div>
        <div style={{width: '100%', minWidth: '210px'}}>
          <Typography level="body-xs">Until</Typography>
          <Input type="datetime-local" variant="soft" name='td' value={searchParamState.td} min={searchParamState.fd ? searchParamState.fd : undefined} onChange={onSearchChange} />
        </div>
        <IconButton variant="outlined" color='primary' sx={{mt: 2, width: {sm: '100%', xs: '100%'}, maxWidth: {sm: '100%', md: '80px'}}} onClick={onSearchClick}>
          <SearchIcon/>
        </IconButton>
      </div>
    </div>
  );
};

export default TopFindBar;
