import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "flag-icon-css/css/flag-icons.min.css";
import { useCookies } from 'react-cookie';
import Logo from "../../img/logo.png";
import { logout } from "../../store/actions/auth";
import { LOGIN_SUCCESS } from "../../store/actions/types";

import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Dropdown from '@mui/joy/Dropdown';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Typography from '@mui/joy/Typography';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { setModalSign } from "../../store/actions/modal";
import SignModal from "./SignModal";
import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import { Button } from "@mui/joy";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

const Header = () => {
  let navigate = useNavigate();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [cookies, removeCookie] = useCookies(['user']);
  useEffect(() => {
    if (!isLoggedIn) {
      if (cookies.user && cookies.user != 'undefined') {
        localStorage.setItem("user", JSON.stringify(cookies.user));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: cookies.user },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setModalSign(""));
      // if (user.role === 'admin'){
      //    navigate("/admin/dashboard");
      // }
    }
  }, [isLoggedIn]);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const handleLogout = () => {
    dispatch(logout());
    removeCookie('user');
    navigate("/home");
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        top: 0, 
        px: 1.5,
        py: 1,
        zIndex: 10,
        backgroundColor: 'background.body',
        borderBottom: '1px solid',
        borderColor: 'divider',
        position: 'sticky',
      }}>
      <Box>
          <Link to="/">
              <Box sx={{height: 40}}>
                <img src={Logo} alt="gauto" style={{height: '100%'}}/>
              </Box>
          </Link>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center',width: '100%',padding: '0px 100px',justifyContent: 'center',position: 'relative'}}>
        
        {/* {isLoggedIn && <Button component={Link} to={'/'} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>Home</Button>} */}
        {isLoggedIn && <Button component={Link} to={`${"/profileview/"}`+user.id} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>Profile</Button>}
        {isLoggedIn && <Button component={Link} to={'/fleet'} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>My Fleet</Button>}
        {isLoggedIn && <Button component={Link} to={'/booking'} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>View Booking</Button>}
        {isLoggedIn && <Button component={Link} to={'/car-add'} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>Add Your Car</Button>}
        {(isLoggedIn && user.role === 'admin')&& <Button component={Link} to={'/admin/dashboard'} variant='outlined' sx={{mr: 4, display: {sm: 'flex', xs: 'none'}}}>DashBoard</Button>}
        <Dropdown style={{ position: 'absolute',right: '13px'}}>
          <MenuButton variant="plain" size="sm" sx={{ maxWidth: '70px', maxHeight: '40px',position: 'absolute',right: '13px'}}>
            {isLoggedIn && user.photo ? 
              <Avatar src={user.photo} sx={{ maxWidth: '32px', maxHeight: '32px' }}/>
              :<Avatar variant="soft" />
            }
            <ArrowDropDownOutlinedIcon/>
          </MenuButton>
          <Menu placement="bottom-end" size="sm"
            sx={{zIndex: '99999', p: 1, gap: 1, '--ListItem-radius': 'var(--joy-radius-sm)',}}>
            {isLoggedIn ? 
            <>
              <MenuItem>
                <Box sx={{display: 'flex',alignItems: 'center',}}>
                  {user.photo ? 
                  <Avatar src={user.photo} sx={{ borderRadius: '50%' }}/>:
                  <Avatar variant="soft" />
                  }
                  <Box sx={{ ml: 1.5 }}>
                    <Typography level="title-sm" textColor="text.primary">
                      {user.name}
                    </Typography>
                    <Typography level="body-xs" textColor="text.tertiary">
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              {/* <ListDivider /> */}
              {/* <MenuItem component={Link} to={`${"/profileview/"}`+user.id}>
                <OpenInNewRoundedIcon />
                Profile
              </MenuItem>
              <MenuItem component={Link} to="/fleet">
                <GarageOutlinedIcon />
                My Fleet
              </MenuItem>
              <MenuItem component={Link} to="/booking">
                <ChecklistRtlIcon />
                View Booking
              </MenuItem>
              <MenuItem component={Link} to="/car-add" sx={{display: {xs: 'flex', sm: 'none'}}}>
                <PlaylistAddIcon />
                List Your Car
              </MenuItem> */}
              <ListDivider />
              <MenuItem onClick={handleLogout}>
                <LogoutRoundedIcon />
                Log out
              </MenuItem>
            </>:
            
            <>
              <MenuItem onClick={()=>dispatch(setModalSign("login"))}>
                Log In
              </MenuItem>
              <MenuItem onClick={()=>dispatch(setModalSign("register"))}>
                Sign Up
              </MenuItem>
            </>}
          </Menu>
        </Dropdown>
      </Box>
      <SignModal/>
    </Box>
  );
};

export default Header;
