import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import * as React from 'react';

import { useDispatch, useSelector } from "react-redux";
import { setModalSign } from "../../store/actions/modal";
import LoginLayout from './LoginLayout';
import RegisterLayout from './RegisterLayout';


export default function SignModal() {
  const dispatch = useDispatch();
  const { modalSign } = useSelector(state=>state.modal)
  const [layout, setLayout] = React.useState("center");

  return (
      <Modal open={!!modalSign} onClose={() => { dispatch(setModalSign(undefined)); }}>
        <ModalDialog layout={layout}>
            <ModalClose />
            {modalSign == 'login' ? 
            <LoginLayout/>:<RegisterLayout/>
            }
        </ModalDialog>
      </Modal>
  );
}