import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { reset } from "../../store/actions/auth";
import { clearMessage } from "../../store/actions/message";
import { useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';


const Reset = () => {
  let navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get('email');
  const hash = searchParams.get('hash');
  
  const { t } = useTranslation();
  
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError] = useState('');
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const location = useLocation();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn]);

  
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeCPassword = (e) => {
    const cpassword = e.target.value;
    setCPassword(cpassword);
  };

  const isValidate = () => {
    
    if (!password) {
      setError('The password is required!');
      return false;
    } else if (password.length < 6 || password.length > 40) {
      setError('The password must be between 6 and 40 characters.');
      return false;
    }

    if (!cpassword) {
      setError('The confirm password is required!');
      return false;
    } else if (password !== cpassword) {
      setError('The confirm password is incorrect.');
      return false;
    }

    setError('');
    return true;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (!isValidate()) return;

    dispatch(reset(email, hash, password));
  };

  return (
    <main>
    <CssBaseline />
    <Sheet
    sx={{
      width: 300,
      mx: 'auto', // margin left & right
      my: 4, // margin top & bottom
      py: 3, // padding top & bottom
      px: 2, // padding left & right
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      borderRadius: 'sm',
      boxShadow: 'md',
    }}
    variant="outlined"
  >
    <div>
      <Typography level="h4" component="h1" textAlign={"center"}>
        <b>ENTER NEW PASSWORD!</b>
      </Typography>
    </div>
   
    <FormControl>
      <FormLabel>New password</FormLabel>
      <Input
        // html input attribute
        name="password"
        type="password"
        value={password}
        onChange={onChangePassword}
      />
    </FormControl>
    <FormControl>
      <FormLabel>Re-enter password</FormLabel>
      <Input
        // html input attribute
        name="password"
        type="password"
        value={cpassword}
        onChange={onChangeCPassword}
      />
    </FormControl>
    {error && <Alert variant='danger'>{error}</Alert>}
    <Button sx={{ mt: 1 /* margin top */ }} onClick={SubmitHandler}>RESET</Button>
  </Sheet>
  </main>  
  );
};

export default Reset;
