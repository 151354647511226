import React, { Fragment } from "react";
import AllRoute from "../router";
import "./App.css";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

const App = () => {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <AllRoute />
      <ToastContainer/>
    </CssVarsProvider>
  );
};

export default App;
