import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import modal from "./modal"
import car from "./car";

export default combineReducers({
    auth,
    message,
    modal,
    car
});