import { Stack } from '@mui/joy';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import carService from "../../services/car.service";
import * as helper from '../../utils/helper';
import CarGridLayout from '../CarList/CarGridLayout';
import TopFindBar from "../CarList/TopFindBar";
import "./style.scss";

const initialSearch = {fd: helper.getStringFromDateTime(new Date()), td: helper.getStringFromDateTime(new Date()), fa: "", ta: ""};

const Fleet = () => {
  const { userId } = useParams();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(initialSearch);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);


  const handleSearch = async (param) => {
    let res = await carService.searchCars(param ? param : search);
    if(res.data && res.data.success){
      setList(res.data.data);
    }else{
      setList([]);
    }
  };

  const onSearchChange = (e) => {
    setSearch({...search, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    if(!userId && !user){
      navigate("/");
      return;
    }
    let tempSearch = {...initialSearch};
    tempSearch.user_id = userId ? userId : user.id;
    if (queryParams.get('fa')){
      tempSearch.fa = queryParams.get('fa');
    }
    if (queryParams.get('ta')){
      tempSearch.ta = queryParams.get('ta');
    }
    if (queryParams.get('fd')){
      tempSearch.fd = queryParams.get('fd');
    }
    if (queryParams.get('td')){
      tempSearch.td = queryParams.get('td');
    }
    setSearch(tempSearch);
    handleSearch(tempSearch);
  }, [userId]);

  return (
    <Stack direction='column' 
      sx={{
        position: 'fixed',
        bottom: 0, left: 0, right: 0,
        height: `calc(100vh - 57px)`,
        maxWidth: 2000
      }}>
        <TopFindBar data={search} onSearchChange={onSearchChange} onSearch={handleSearch}/>
        <CarGridLayout list={list}/>
    </Stack>
  );
};

export default Fleet;
