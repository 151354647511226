import React, { Fragment } from "react";
import Header from "../../../components/header";
import Profile from "../../../components/Profile";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
const ProfilePage = () => {
  const { user } = useSelector(state => state.auth);
  return (
    <div className="flex-container">
      <Header />
      <Profile id={user?.id}/>
      <Footer />
    </div>
  );
};
export default ProfilePage;
