import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from "react";
import { RangeSlider, Button } from 'rsuite';

import { carTypes, manufacturers } from '../../utils/helper';
import "./morefilterstyle.scss";
import { Typography } from '@mui/joy';

const filters = {
  fuel_type: {
    label: 'Gas',
    type: 'select',
    values: ['Gas', 'Diesel', 'Electric', 'Hydrogen']
  },
  car_type: {
    label: 'Type',
    type: 'select',
    values: carTypes,
  },
  year: {
    label: 'Year',
    type: 'slider',
    minValue: 1950,
    maxValue: 2024,
  },
  manufacturer: {
    label: 'Manufacturer',
    type: 'select',
    values: manufacturers,
  },
  transmission: {
    label: 'Transmission',
    type: 'select',
    values: ['Automatic', 'Manual']
  },
  seats_number: {
    label: 'Number of Seats',
    type: 'select',
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  doors_number: {
    label: 'Number of Doors',
    type: 'select',
    values: [1, 2, 3, 4, 5]
  }
}

const MoreFilter = () => {
  const [isSearchVisible, setSearchVisible] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width:767px)');

  const onSearchChange = (e) => {
  }

  useEffect(() => {
    if(isSmallScreen){
      setSearchVisible(false);
    }
  }, [isSmallScreen])

  return (
    <>
      <div className="more-filter">
        {Object.keys(filters).map((key) => {
          if(filters[key].type == 'select'){
            return (
              <div key={key} className='filter-row'>
                <Typography level={'title-sm'}>{filters[key].label}</Typography>
                <select id={key}>
                  {filters[key].values.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            )
          }
          if(filters[key].type == 'slider'){
            return (
              <div key={key} className='filter-row'>
                <label htmlFor={key}>{filters[key].label}</label>
                <p className='value-label'>All Years</p>
                <RangeSlider style={{margin: '0px 20px'}} progress min={filters[key].minValue} max={filters[key].maxValue}/>
              </div>
            )
          }
        })}
      </div>
    </>
  );
};

export default MoreFilter;
