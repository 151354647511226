import React, { Fragment } from "react";
import Header from "../../../components/header";
import FindCar from "../../../components/findcar";
import Footer from "../../../components/Footer";

const HomePage = () => {
  return (
    <div className="flex-container">
      <Header />
      <FindCar />
      <Footer />
    </div>
  );
};
export default HomePage;
