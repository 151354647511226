import { addDays, isAfter, isBefore } from 'date-fns';
import React, { useCallback, useState } from 'react';
import * as helper from '../../utils/helper';
import './calendar.css';
import './styles.css';

import { Calendar } from 'rsuite';
import carService from '../../services/car.service';
import SettingsPopover from './SettingsPopover';
import { useSelector } from 'react-redux';


const CarCalendar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [settings, setSettings] = useState({});
    const [isApplying, setIsApplying] = useState({});
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [isRepeatWeekly, setIsRepeatWeekly] = useState(false);
    const { user } = useSelector(state => state.auth);

    const handleChange = (date) => {
        debugger;
        if (props.pageType == 4){
            props.setSelectDate(date);
            return;
        }
        setCurrentDate(date);
        let str = helper.getDateString(date);
        let temp = {}
        let bExit = false;
        for (const daily of props.carInfo.daily_settings) {
            let strDate = new Date(daily.date).toLocaleString();
            if (helper.getDateString(new Date(strDate)) == str) {
                temp = { ...daily };
                temp.pickup_zip = JSON.parse(daily.pickup_zip);
                temp.return_zip = JSON.parse(daily.return_zip);
                temp.unavailable_start = new Date(daily.unavailable_start.toLocaleString());
                temp.unavailable_end = new Date(daily.unavailable_end.toLocaleString());
                temp.unavailable_start_time = new Date(daily.unavailable_start.toLocaleString());
                temp.unavailable_end_time = new Date(daily.unavailable_end.toLocaleString());
                temp.exit = true;
                if(daily.block)
                    setIsUnavailable(true);
                else
                    setIsUnavailable(false);
                bExit = true;
                break;
            }
        }
        
        temp.until = date;
        if (bExit == false) {
            setIsUnavailable(false);
            let parsedAddresses = props.carInfo.addresses;
            parsedAddresses=Array.isArray(parsedAddresses) ? parsedAddresses : Array.isArray(JSON.parse(parsedAddresses))?JSON.parse(parsedAddresses):[];
            temp.exit = false;
            temp.price='50';
            temp.distance='50';
            temp.pickup_radius='50';
            temp.return_radius='50';
            temp.pickup_zip=parsedAddresses.length>0?parsedAddresses[0]:null;
            temp.return_zip=parsedAddresses.length>0?parsedAddresses[0]:null;
            temp.unavailable_start = date;
            temp.unavailable_end = date;
            temp.unavailable_start_time = new Date(new Date().setHours(0, 0, 0, 0));
            temp.unavailable_end_time = new Date(new Date().setHours(23, 59, 59, 0));
        }
        // debugger;
        console.log("hahahahaha" + JSON.stringify(props.carInfo.daily_settings))
        setSettings(temp);
        setIsOpen(true)
    }

    const handleApply = async (type, data) => {
        setIsApplying({ [type]: true });
        debugger;
        try {

            let temp = { ...props.carInfo, daily_settings: [...props.carInfo.daily_settings] };
            let iterDate = currentDate;
            let exist = false;
            let strIterDate = '';
            let tmpDate;
            let unavailable_start_time;
            let unavailable_end_time;
            if (type == 'removeUnavailable') {
                debugger
                
                let res = await carService.removeDailySettings(data.car_id,data.createdAt);
                if (res.data.success) {
                    props.setCarInfo(res.data.data);
                    setIsUnavailable(false);
                } else {
                    console.log("apply error :", res.data.msg);
                }
            } else {
                if (type == 'unavailable') {
                    debugger;
                    iterDate = new Date(data.unavailable_start);
                    let unavailableEnd = new Date(data.unavailable_end);
                    while (!isAfter(iterDate, unavailableEnd)) {
                        setIsUnavailable(true);
                        strIterDate = helper.getDateString(iterDate);
                        exist = false;
                        
                        for (const daily of temp.daily_settings) {
                            let strDate = daily.date.toLocaleString();

                            if (helper.getDateString(new Date(strDate)) != strIterDate) continue;
                            daily.block = true;
                            daily.price = data.price;
                            daily.distance = data.distance;
                            daily.pickup_zip = data.pickup_zip;
                            daily.pickup_radius = data.pickup_radius;
                            daily.return_zip = data.return_zip;
                            daily.return_radius = data.return_radius;
                            daily.todo = 'update';
                            exist = true;
                            
                            if (strIterDate ==helper.getDateString(data.unavailable_start) && strIterDate == helper.getDateString(data.unavailable_end)) {
                                daily.unavailable_start = new Date(helper.getDateString(data.unavailable_start)+'T'+helper.getTimeString(data.unavailable_start_time));
                                daily.unavailable_end = new Date(helper.getDateString(data.unavailable_end)+'T'+helper.getTimeString(data.unavailable_end_time));
                            }
                            else if (strIterDate == helper.getDateString(data.unavailable_start)) {
                                daily.unavailable_start = new Date(helper.getDateString(data.unavailable_start)+'T'+helper.getTimeString(data.unavailable_start_time));
                                daily.unavailable_end = new Date(iterDate.setHours(23, 59, 59));
                            }
                            else if (strIterDate == helper.getDateString(data.unavailable_end)) {
                                daily.unavailable_start = new Date(iterDate.setHours(0, 0, 0));
                                daily.unavailable_end = new Date(helper.getDateString(data.unavailable_end)+'T'+helper.getTimeString(data.unavailable_end_time));
                            }
                            else {
                                daily.unavailable_start = new Date(iterDate.setHours(0, 0, 0));
                                daily.unavailable_end = new Date(iterDate.setHours(23, 59, 59));
                            }
                        }
                        
                        if (!exist) {
                            unavailable_start_time=new Date(helper.getDateString(data.unavailable_start)+'T'+helper.getTimeString(data.unavailable_start_time));
                            unavailable_end_time=new Date(helper.getDateString(data.unavailable_end)+'T'+helper.getTimeString(data.unavailable_end_time));
                            if (strIterDate == helper.getDateString(data.unavailable_start) && strIterDate == helper.getDateString(data.unavailable_end)) {
                                tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: unavailable_start_time, unavailable_end: unavailable_end_time, todo: 'create' });
                            }
                            else if (strIterDate == helper.getDateString(data.unavailable_start)) {
                                tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: unavailable_start_time, unavailable_end: new Date(iterDate.setHours(23, 59, 59)), todo: 'create' });
                            }
                            else if (strIterDate == helper.getDateString(data.unavailable_end)) {
                                tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: new Date(iterDate.setHours(0, 0, 0)), unavailable_end: unavailable_end_time, todo: 'create' });
                            }
                            else {
                                tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: new Date(iterDate.setHours(0, 0, 0)), unavailable_end: new Date(iterDate.setHours(23, 59, 59)), todo: 'create' });
                            }
                        }
                        iterDate.setHours(0, 0, 0);
                        iterDate.setDate(iterDate.getDate() + 1);
                    }

                    if (isRepeatWeekly) {
                        // Repeat for the next weeks
                        let weekStart = data.unavailable_start;
                        let weekEnd = data.unavailable_end;
                        const endDate = addDays(data.unavailable_start, 90);
                        for (let i = 1; i <= 12; i++) { // Repeat for 12 weeks (3 months)
                            const nextWeekDateStart = addDays(weekStart, i * 7);
                            const nextWeekDateEnd = addDays(weekEnd, i * 7);
                            if (isAfter(nextWeekDateStart, endDate)) break; // Stop if beyond 3 months
                            iterDate = new Date(nextWeekDateStart);
                            while (!isAfter(iterDate, nextWeekDateEnd)) {
                                strIterDate = helper.getDateString(iterDate);
                                exist = false;
                                
                                for (const daily of temp.daily_settings) {
                                    let strDate = daily.date.toLocaleString();
                                    if (helper.getDateString(new Date(strDate)) != strIterDate) continue;
                                    daily.block = true;
                                    daily.price = data.price;
                                    daily.distance = data.distance;
                                    daily.pickup_zip = data.pickup_zip;
                                    daily.pickup_radius = data.pickup_radius;
                                    daily.return_zip = data.return_zip;
                                    daily.return_radius = data.return_radius;
                                    daily.todo = 'update';
                                    exist = true;
                                    
                                    if (strIterDate == helper.getDateString(nextWeekDateStart) && strIterDate == helper.getDateString(nextWeekDateEnd)) {
                                        daily.unavailable_start = new Date(helper.getDateString(nextWeekDateStart)+'T'+helper.getTimeString(data.unavailable_start_time));
                                        daily.unavailable_end = new Date(helper.getDateString(nextWeekDateEnd)+'T'+helper.getTimeString(data.unavailable_end_time));
                                    }
                                    else if (strIterDate == helper.getDateString(nextWeekDateStart)) {
                                        daily.unavailable_start = new Date(helper.getDateString(nextWeekDateStart)+'T'+helper.getTimeString(data.unavailable_start_time));
                                        daily.unavailable_end = new Date(iterDate.setHours(23, 59, 59));
                                    }
                                    else if (strIterDate == helper.getDateString(nextWeekDateEnd)) {
                                        daily.unavailable_start = new Date(iterDate.setHours(0, 0, 0));
                                        daily.unavailable_end = new Date(helper.getDateString(nextWeekDateEnd)+'T'+helper.getTimeString(data.unavailable_end_time));
                                    }
                                    else {
                                        daily.unavailable_start = new Date(iterDate.setHours(0, 0, 0));
                                        daily.unavailable_end = new Date(iterDate.setHours(23, 59, 59));
                                    }
                                }
                                if (!exist) {
                                    unavailable_start_time=new Date(helper.getDateString(iterDate)+'T'+helper.getTimeString(data.unavailable_start_time));
                                    unavailable_end_time=new Date(helper.getDateString(iterDate)+'T'+helper.getTimeString(data.unavailable_end_time));
                                    if (strIterDate == helper.getDateString(nextWeekDateStart) && strIterDate == helper.getDateString(nextWeekDateEnd)) {
                                        tmpDate = new Date(iterDate.setHours(0, 0, 0));

                                        temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: unavailable_start_time, unavailable_end: unavailable_end_time, todo: 'create' });
                                    }
                                    else if (strIterDate == helper.getDateString(nextWeekDateStart)) {
                                        tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                        temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: unavailable_start_time, unavailable_end: new Date(iterDate.setHours(23, 59, 59)), todo: 'create' });
                                    }
                                    else if (strIterDate == helper.getDateString(nextWeekDateEnd)) {
                                        tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                        temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: new Date(iterDate.setHours(0, 0, 0)), unavailable_end: unavailable_end_time, todo: 'create' });
                                    }
                                    else {
                                        tmpDate = new Date(iterDate.setHours(0, 0, 0));
                                        temp.daily_settings.push({ date: tmpDate,...data, block: true, unavailable_start: new Date(iterDate.setHours(0, 0, 0)), unavailable_end: new Date(iterDate.setHours(23, 59, 59)), todo: 'create' });
                                    }
                                }
                                iterDate.setHours(0, 0, 0);
                                iterDate.setDate(iterDate.getDate() + 1);
                            }
                        }
                    }
                } else {
                    let until = new Date(data.until); // Create a new Date object
                    //while (!isAfter(iterDate, until)) {
                        //strIterDate = helper.getDateString(iterDate);
                        strIterDate = helper.getDateString(until);
                        exist = false;
                        for (const daily of temp.daily_settings) {
                            let strDate = daily.date.toLocaleString();

                            if (helper.getDateString(new Date(strDate)) != strIterDate) continue;
                            
                            daily.price = data.price;
                            daily.distance = data.distance;
                            daily.pickup_zip = data.pickup_zip;
                            daily.pickup_radius = data.pickup_radius;
                            daily.return_zip = data.return_zip;
                            daily.return_radius = data.return_radius;
                            daily.pickup_pos = data.pickup_pos;
                            daily.return_pos = data.return_pos;
                            daily.todo = 'update';
                            exist = true;
                        }
                        if (!exist) {
                            // tmpDate = new Date(iterDate.setHours(data.unavailable_start_time.getHours(), data.unavailable_start_time.getMinutes(), data.unavailable_start_time.getSeconds()));
                            // temp.daily_settings.push({ date: tmpDate, ...data,unavailable_start: new Date(iterDate.setHours(0, 0, 0)), unavailable_end: new Date(iterDate.setHours(23, 59, 59)), todo: 'create' });
                            //tmpDate = new Date(until.setHours(data.unavailable_start_time.getHours(), data.unavailable_start_time.getMinutes(), data.unavailable_start_time.getSeconds()));
                            tmpDate = new Date(iterDate.setHours(0, 0, 0));
                            temp.daily_settings.push({ date: tmpDate, ...data,unavailable_start: new Date(until.setHours(0, 0, 0)), unavailable_end: new Date(until.setHours(23, 59, 59)), todo: 'create' });
                            // if (type == 'price') {
                            //     temp.daily_settings.push({ date: strIterDate, price: data.price, todo: 'create' });
                            // } else {
                            //     temp.daily_settings.push({ date: strIterDate, ...data, price: props.carInfo.price, todo: 'create' });
                            // }
                        }
                    //     iterDate.setHours(0, 0, 0);
                    //     iterDate.setDate(iterDate.getDate() + 1);
                    // }
                }
                let res = await carService.saveDailySettings(temp);
                if (res.data.success) {
                    props.setCarInfo(res.data.data);
                } else {
                    console.log("apply error :", res.data.msg);
                }
            }
        } catch (e) {
            console.log("Applying error - ", e);
        }
        setIsApplying({ [type]: false });
    }
    const getDailySetting = useCallback((date) => {
        if (props.carInfo.daily_settings) {
            let str = helper.getDateString(date);
            for (const daily of props.carInfo.daily_settings) {
                if (helper.getDateString(new Date(daily.date.toLocaleString())) == str) {
                    // return {...daily,unavailable_start:daily.unavailable_start}
                    return daily;
                }
            }
        }
        return null;
    }, [props.carInfo.daily_settings])

    let clr = '#700000';
    const getBook = useCallback((date) => {

        // user.id
        if (props.carInfo.books) {
            let str = helper.getDateString(date);
            const sortedData = props.carInfo.books.sort((a, b) => b.user_id - a.user_id);

            for (const book of sortedData) {

                let pickupDate = new Date((new Date(book.pickup_date + 'T' + book.pickup_time+'Z')).toLocaleString());
                let returnDate = new Date((new Date(book.return_date + 'T' + book.return_time+'Z')).toLocaleString());
                
                let pickup_time=helper.convertLocalTimeToUTC0(`1970-01-01T00:00:00`);
                let return_time=helper.convertLocalTimeToUTC0(`1970-01-01T23:59:59`);
                
                if(helper.getDateString(pickupDate) == helper.getDateString(date))
                    pickup_time=book.pickup_time
                if(helper.getDateString(returnDate) == helper.getDateString(date))
                    return_time=book.return_time

                pickupDate = pickupDate.setHours(0, 0, 0, 0);
                returnDate = returnDate.setHours(23, 59, 59, 0);

                const date1 = new Date(date);
                date1.setHours(0, 1, 0, 0);

                
                if (helper.isDateInRange(pickupDate, returnDate, date1)) {
                    if (props.pageType === 4) {
                        if (user.id === book.user_id) {
                            return { book: book, color: '#00ff55', flag: true,pickup_time:pickup_time,return_time:return_time };
                        } else {
                            return { book: book, color: book.color ? book.color : clr, flag: false,pickup_time:pickup_time,return_time:return_time };
                        }
                    } else {
                        return { book: book, color: book.color ? book.color : clr, flag: false,pickup_time:pickup_time,return_time:return_time };
                    }
                }

            }
        }
        return null;
    }, [props.carInfo.books])

    function renderCell(date) {
        
        const data = getDailySetting(date);
        const book = getBook(date);
        const isAfterNewDate = isAfter(date, addDays(new Date(), -1));
        
        return (
            <>
                <div style={{
                    height: 'calc(100% - 4px)',
                    width: 'calc(100% - 10px)',
                    position: 'absolute',
                    top: '0px',
                    backgroundColor: '#0000006b',
                    left: '0.5px',
                    marginLeft: '5px',
                    marginTop: '2px',
                    opacity: isAfterNewDate ? '0' : '0.1'
                }}></div>
                <div style={{ opacity: isAfterNewDate ? 1 : 0.5 }}>
                    <div className={`custom-cell-content ${data && data.block ? 'cell-block' : 'cell-block-invisible'}`}
                        style={{
                            left: data ? `calc(${helper.getPercentofDiff(helper.getUTC0FromLocalTime0(), helper.getTimeStringFromDate(data.unavailable_start))}% + 5px)` : undefined,
                            // ...(data && data.unavailable_start !== "00:00:00" ? { right: '5px', left: 'auto' } : {}),
                            width: data ? `calc(${helper.getPercentofDiff(helper.getTimeStringFromDate(data.unavailable_start), helper.getTimeStringFromDate(data.unavailable_end))}% - 10px)` : undefined
                        }}>
                    </div>
                    <div className={`custom-cell-content`}>
                        <div className='cell-text-content'>
                            {data ? (<>
                                {data.price ? (<p>${data.price}</p>) : (<p>$50</p>)}
                                {data.distance ? (<p>{data.distance} miles</p>) : (<p>50miles</p>)}
                            </>) : (<><p>$50</p><p>50 miles</p></>)
                            }
                        </div>
                        {book ? (
                            <div class="cell-book">
                                {/* <div class="cell-book-bar" style={{
                                    backgroundColor: book.color,
                                    left: book.book.pickup_time !== "00:00:00" ?
                                        `${(parseInt(book.book.pickup_time.split(':')[0]) * 100 / 24)}%` : undefined,
                                    ...(book.book.pickup_time !== "00:00:00" ? { right: '5px', left: 'auto' } : {}),
                                    width: book.book.pickup_time !== "00:00:00" ?
                                        `calc(${100 - (parseInt(book.book.pickup_time.split(':')[0]) * 100 / 24)}% - 0px)` :
                                        book.book.return_time !== "00:00:00" ?
                                            `calc(${(parseInt(book.book.return_time.split(':')[0]) * 100 / 24)}% - 0px)` : undefined
                                }}></div> */}
                                <div class="cell-book-bar" style={{
                                    backgroundColor: book.color,
                                    left:`calc(${helper.getPercentofDiff(helper.getUTC0FromLocalTime0(), book.pickup_time)}%)`,
                                    // ...(data && data.unavailable_start !== "00:00:00" ? { right: '5px', left: 'auto' } : {}),
                                    width:`calc(${helper.getPercentofDiff(book.pickup_time, book.return_time)}%)`
                                }}
                                ></div>
                                <p class="cell-book-status">{book.book.status}</p>
                            </div>
                        ) : (<></>)}
                    </div>
                </div>
            </>
        );
    }

    return (
        <div>
                
            {isOpen && <SettingsPopover car={props.carInfo} onClose={() => setIsOpen(false)} data={settings} onApply={handleApply} isApplying={isApplying} setIsRepeatWeekly={setIsRepeatWeekly} isUnavailable={isUnavailable} setIsUnavailable={setIsUnavailable} />}
            <Calendar className="car-calendar" bordered renderCell={renderCell} onChange={handleChange} />
        </div>
    );
};

export default CarCalendar;