import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';

import { useDispatch } from "react-redux";
import { setModalSign } from '../../store/actions/modal';
import GoogleButton from './GoogleButton';
import { isEmail } from "validator";
import FormHelperText from '@mui/joy/FormHelperText';
import { register } from "../../store/actions/auth";
import { useNavigate } from "react-router-dom";

export default function RegisterLayout() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [layout, setLayout] = useState("center");
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState({firstName: '', lastName: '', email: '', pwd: '', cpwd: ''});
  const [error, setError] = useState({});

  const handleChange = (e) => {
    let name = e.target.name;
    let v = e.target.value;
    setValue({...value, [name]: v});
    if(isClicked){
      setError({...error, [name]: isValid(name, v)});
    }
  }

  const isValid = (name, v) => {
    let errorMsg = '';
    if(name === 'email'){
      if (!v) {
        errorMsg = "The email is required!";
      } else if (!isEmail(v)) {
        errorMsg = "The email is not valid.";
      }
    }else if(name === 'firstName'){
      if (!v) {
        errorMsg = 'The first name is required!';
      }
    }else if(name === 'lastName'){
      if (!v) {
        errorMsg = 'The last name is required!';
      }
    }else if(name === 'pwd'){
      if (!v) {
        errorMsg = 'The password is required!';
      }
    }
    else if(name === 'cpwd'){
      if (!v) {
        errorMsg = 'Confirm your password.';
      }else if(v !== value.pwd){
        errorMsg = 'Confirm password is not correct';
      }
    }
    return errorMsg
  }

  const isValidate = () => {
    let emailError = isValid('email', value.email);
    let pwdError = isValid('pwd', value.pwd);
    let cpwdError = isValid('cpwd', value.cpwd);
    let firstNameError = isValid('firstName', value.firstName);
    let lastNameError = isValid('lastName', value.lastName);
    setError({email: emailError, pwd: pwdError, cpwd: cpwdError, firstName: firstNameError, lastName: lastNameError});
    return (emailError === '' && pwdError === '' && cpwdError === '' && firstNameError === '' && lastNameError === '') ;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!isValidate()) return;
    dispatch(register(value.firstName, value.lastName, value.email, value.pwd));
  };

  return (
      <Stack direction={'column'}
        sx={{
          overflow: 'auto',
          mx: 'calc(-1 * var(--ModalDialog-padding))',
          px: 'var(--ModalDialog-padding)',
        }}
      >
        <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Sign up</Typography>
                <Typography level="body-sm">
                  Already have an account?{' '}
                  <Link level="title-sm" onClick={(e)=>dispatch(setModalSign("login"))}>
                    Log in!
                  </Link>
                </Typography>
              </Stack>
              <GoogleButton isRegister={true}/>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                  '--Divider-lineColor': {
                    xs: '#FFF',
                    md: 'var(--joy-palette-divider)',
                  },
                },
              })}
            >
              or
            </Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form>
                <Stack direction="row" gap={2}>
                  <FormControl sx={{minWidth: 0}} error={!!error.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input type="text" name="firstName" value={value.firstName} onChange={handleChange}/>
                    <FormHelperText>{error.firstName}</FormHelperText>
                  </FormControl>
                  <FormControl sx={{minWidth: 0}} error={!!error.lastName}>
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" name="lastName" value={value.lastName} onChange={handleChange}/>
                    <FormHelperText>{error.lastName}</FormHelperText>
                  </FormControl>
                </Stack>
                <FormControl error={!!error.email}>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" value={value.email} onChange={handleChange}/>
                  <FormHelperText>{error.email}</FormHelperText>
                </FormControl>
                <FormControl error={!!error.pwd}>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="pwd" value={value.pwd} onChange={handleChange}/>
                  <FormHelperText>{error.pwd}</FormHelperText>
                </FormControl>
                <FormControl error={!!error.cpwd}>
                  <FormLabel>Confirm Password</FormLabel>
                  <Input type="password" name="cpwd" value={value.cpwd} onChange={handleChange}/>
                  <FormHelperText>{error.cpwd}</FormHelperText>
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button fullWidth onClick={SubmitHandler}>
                    Sign up
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
      </Stack>
  );
}