import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState, CSSProperties } from "react";
import { CircleLoader } from "react-spinners";


import { Col, Row } from "react-bootstrap";
import { FaSlidersH, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import * as helper from '../../utils/helper';
import TopFindBar from "./TopFindBar";

import { Box, Button, Stack, Typography } from "@mui/joy";
import carService from "../../services/car.service";
import useOutsideClick from "../../utils/useOustsideclick";
import MoreFilter from "./MoreFilter";
import "./style.scss";
import CarGridLayout from './CarGridLayout';
import AspectRatio from '@mui/joy/AspectRatio';
import Skeleton from '@mui/joy/Skeleton';
import { useSearchParam } from '../../hooks/useSearchParam';

const initialSearch = { fd: helper.getStringFromDateTime(new Date(new Date(new Date().setDate(new Date().getDate() + 3)).setHours(0,0,0,0))), td: helper.getStringFromDateTime(new Date(new Date(new Date().setDate(new Date().getDate() + 7)).setHours(23,59,59,0))), fa: "", ta: "", fr: 50, tr: 50 };
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#36d7b7",
};
const CarList = () => {
  const location = useLocation();
  const filterRef = useRef(null);
  const [search, setSearch] = useState(initialSearch)
  const [filterOpen, setFilterOpen] = useState(false)
  const [list, setList] = useState([])
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const [loading, setLoading] = React.useState(true);
  let [color, setColor] = useState("#36d7b7");
  const queryParams = new URLSearchParams(location.search);
  let bFlag = false;
  const { searchParamState, setSearchParamState } = useSearchParam();

  const handleOutsideFilterClick = () => {
    setFilterOpen(false);
  };
  useOutsideClick(filterRef, handleOutsideFilterClick);

  const handleSearch = async (param = null) => {
    debugger
    setLoading(true);
    setSearch({...search,...param})
    
    let res = await carService.searchCars(param ? param : search);
    if (res.data && res.data.success) {
      setLoading(false);
      setList(res.data.data);

    } else {
      setLoading(false);
      setList([]);
    }
    setLoading(false);
  };

  const onSearchChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    setSearchParamState({ ...search, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    let temp = { fa: queryParams.get('fa') || "", ta: queryParams.get('ta') || "", fr: queryParams.get('fr') || 50, tr: queryParams.get('tr') || 50 }

    if (queryParams.get('fd')) {
      temp.fd = queryParams.get('fd');
    }
    if (queryParams.get('td')) {
      temp.td = queryParams.get('td');
    }

    let paramSearch = { ...initialSearch, ...temp };
    setSearch(paramSearch);
    setSearchParamState(paramSearch)
    if (bFlag == false) {
      handleSearch(paramSearch);
      bFlag = true
    }
  }, [])

  return (
    <Stack direction='column'
      sx={{
        position: 'fixed',
        bottom: 0, left: 0, right: 0,
        height: `calc(100vh - 57px)`,
        maxWidth: 2000
      }}>
      <TopFindBar data={search} onSearch={handleSearch} setSearch={setSearch} onSearchChange={onSearchChange} />
      <CircleLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <div className="filter-container">
        <Stack direction='row' alignItems={'center'} gap={2}
          sx={{
            px: 2, py: 1,
          }}
        >
          <div className={`filter-overlay ${filterOpen && 'opened'}`}></div>
          {!isSmallScreen && <>
            <Button size='sm' variant='soft'>Sort By</Button>
            <Button size='sm' variant='soft'>Price Options</Button>
            <Button size='sm' variant='soft'>Pickup Options</Button>
          </>}
          {isSmallScreen && <Typography><span>{list.length}</span> cars at or near address</Typography>}
          <div className="filter-item">
            <Button size='sm' variant='soft' style={{ marginLeft: isSmallScreen ? '4px' : '20px' }} onClick={() => setFilterOpen(true)}>
              {isSmallScreen ? "Filters" :
                <>
                  <FaSlidersH style={{ marginRight: '4px' }} />More filters
                </>}
            </Button>
            <div ref={filterRef} className={`filter-modal ${filterOpen && 'opened'}`}>
              <div className='close-btn' onClick={() => setFilterOpen(false)}><FaTimes /></div>
              <div className='filter-modal-header'>
                <Typography>Filter</Typography>
              </div>
              <MoreFilter />
              <div className='filter-modal-footer'>
                <Button variant='outlined' color='primary' onClick={() => setFilterOpen(false)}>Apply</Button>
              </div>
            </div>
          </div>
        </Stack>
      </div>
      <CarGridLayout list={list} />
    </Stack>
  );
};

export default CarList;
