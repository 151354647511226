/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FaceIcon from '@mui/icons-material/Face';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import UserService from "../../../../services/user.service";
import {IRootState} from '../../type';
import moment from 'moment';
import { useDispatch} from "react-redux";
import { setMessage } from "../../../../store/actions/message";
import { showToast } from "../../../../utils/toasts"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RowMenu({userId,setDeleteFlag,deleteFlag}) {
  const [id,setId] = React.useState('');
  const { user } = useSelector((state:IRootState) => state.auth);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(()=>{
    setId(userId);
  },[])
  const onHandleEdit=()=>{
    navigate(`/admin/userprofile/${id}`);
  }
  const onHandleDelete=()=>{
    if(user.role=='admin' && user.id == Number(id))
      showToast("error", "You can't delete the user with admin role.");
    else{
      UserService.deleteUser(id)
      .then((res) => {
        showToast("success", res.data.msg);
        dispatch(setMessage(res.data.msg));
        setDeleteFlag(!deleteFlag);
      })
      .catch((err) => {
          showToast("error", err.message);
      });
    }
  }
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem onClick={onHandleEdit}>Edit</MenuItem>
        {/* <MenuItem>Rename</MenuItem>
        <MenuItem>Move</MenuItem> */}
        <Divider />
        <MenuItem color="danger" onClick={onHandleDelete}>Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default function UserTable() {
  const [order, setOrder] = React.useState<Order>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [pageNum, setPageNum] = React.useState(0);
  const [pageArray,setPageArray] = React.useState([]);
  const [count,setCount] = React.useState(0);
  const { user } = useSelector((state:IRootState) => state.auth);
  const [searchName,setSearchName] = React.useState('');
  const [searchRole,setSearchRole] = React.useState('');
  const [deleteFlag,setDeleteFlag] = React.useState(false);
  
  let navigate = useNavigate();
  React.useEffect(() => {
    if(!user){
        navigate('/home');
        return;
    }
    (async () => {
      const data={offset:offset,limit:limit,name:'',role:''}
      UserService.getUsers(data)
      .then((res) => {
          setUsers(res.data.users);
          setCount(res.data.count)
          let pNum=Math.ceil(res.data.count/limit)
          setPageNum(pNum);
          const arr=[];
          for(let i=0; i< pNum; i++)
            arr.push(i+1);
          setPageArray(arr);
      })
      .catch((err) => {
          console.log(err)
      });
    })();

  }, [])

  React.useEffect(() => {
    if(!user){
        navigate('/home');
        return;
    }
    (async () => {
      const data={offset:offset,limit:limit,name:'',role:''}
      UserService.getUsers(data)
      .then((res) => {
          setUsers(res.data.users);
          setCount(res.data.count)
          let pNum=Math.ceil(res.data.count/limit)
          setPageNum(pNum);
          const arr=[];
          for(let i=0; i< pNum; i++)
            arr.push(i+1);
          setPageArray(arr);
      })
      .catch((err) => {
          console.log(err)
      });
    })();

  }, [deleteFlag])

  React.useEffect(() => {
    (async () => {
      const data={offset:offset,limit:limit,name:searchName,role:searchRole}
      UserService.getUsers(data)
      .then((res) => {
          setUsers(res.data.users);
          let pNum=Math.ceil(res.data.count/limit)
          setPageNum(pNum);
          const arr=[];
          for(let i=0; i< pNum; i++)
            arr.push(i+1);
          setPageArray(arr);
      })
      .catch((err) => {
          console.log(err)
      });
    })();

  }, [offset,searchRole,searchName])
  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Role</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by role"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          value={searchRole}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null,
          ) => {
            newValue == null? setSearchRole('') : setSearchRole(newValue)
          }}
        >
          <Option value=""></Option>
          <Option value="admin">Admin</Option>
          <Option value="user">User</Option>
          
        </Select>
      </FormControl>
      {/* <FormControl size="sm">
        <FormLabel>Category</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="refund">Refund</Option>
          <Option value="purchase">Purchase</Option>
          <Option value="debit">Debit</Option>
        </Select>
      </FormControl> */}
      {/* <FormControl size="sm">
        <FormLabel>Customer</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="olivia">Olivia Rhye</Option>
          <Option value="steve">Steve Hampton</Option>
          <Option value="ciaran">Ciaran Murray</Option>
          <Option value="marina">Marina Macdonald</Option>
          <Option value="charles">Charles Fulton</Option>
          <Option value="jay">Jay Hoper</Option>
        </Select>
      </FormControl> */}
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for name</FormLabel>
          <Input size="sm" 
          placeholder="Search"  
          value={searchName}
          onChange={(event) => setSearchName(event.target.value)} 
          startDecorator={<SearchIcon />} />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== limit
                  }
                  checked={selected.length === limit}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? users.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === limit
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 120, padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Id
                </Link>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>Date</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Role</th>
              <th style={{ width: 240, padding: '12px 6px' }}>User</th>
              <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {stableSort(users, getComparator(order, 'id')).map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    
                    checked={selected.includes(row.id as string)}
                    
                    color={selected.includes(row.id as string) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                    
                          ? ids.concat(row.id  as string)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography level="body-xs">{row.id}</Typography>
                </td>
                <td>
                
                  <Typography level="body-xs">{moment(new Date(row['createdAt'])).format('MMM D, YYYY, h:mm:ss A')}</Typography>
                </td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    startDecorator={
                      {
                        admin: <AdminPanelSettings />,
                        renter: <CarRentalIcon />,
                        customer: <FaceIcon />,
                      }[row['role']]
                    }
                    color={
                      {
                        admin: 'success',
                        renter: 'neutral',
                        customer: 'danger',
                      }[row['role']] as ColorPaletteProp
                    }
                  >
                    {row['role']}
                  </Chip>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* <Avatar size="sm">{row['customer']['initial']}</Avatar> */}
                    <Avatar size="sm" src={row['photo']}></Avatar>
                    <div>
                      <Typography level="body-xs">{row['name']}</Typography>
                      <Typography level="body-xs">{row['email']}</Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* <Link level="body-xs" component="button">
                      Download
                    </Link> */}
                    <RowMenu userId={row.id} setDeleteFlag={setDeleteFlag} deleteFlag={deleteFlag}/>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={e=>{
            if(offset-limit<0)
              setOffset(0);
            else
              setOffset(offset-limit);
          }}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {pageArray.map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
            onClick={e=>{
              setOffset((Number(e.currentTarget.innerText)-1)*limit)
            }}
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={e=>{
            if(offset+limit>count)
              setOffset(count);
            else
              setOffset(offset+limit);
          }}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
