import { SET_MODAL_SIGN } from "../actions/types";

const initialState = {
    modalSign: undefined,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_MODAL_SIGN:
            return { ...state, modalSign: payload };
        default:
            return state;
    }
}