import React from "react";
import { useTranslation } from "react-i18next";

import CarEdit from "../../../components/CarAdd/CarEdit";
import Footer from "../../../components/Footer";
import Header from "../../../components/header";

const CarEditPage = () => {
  const { t } = useTranslation();

  return (
    <div className='flex-container'>
      <Header />
      <CarEdit />
      <Footer />
    </div>
  );
};
export default CarEditPage;
