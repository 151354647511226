import React, { useState, useEffect } from "react";
import CarService from '../../services/car.service'
import { useSelector,useDispatch } from "react-redux";
import statesArray from '../../utils/states.json';
import { requiredFields, carTypes, features } from "../../utils/helper"
import { Grid, Input, Option, Typography, Button, Textarea, Checkbox, Box, Stack } from "@mui/joy";
import Select from '../CustomSelect';
import AddressField from '../findcar/AddressField';
import Table from '@mui/joy/Table';
import { setCarInfoRedux,getCarInfoRedux} from "../../store/actions/car";

const CarBasicDetail = (props) => {
  const [vinLoading, setVinLoading] = useState(false);
  const { user } = useSelector(state => state.auth);

  const [tmpAddresses, setTmpAddresses] = useState(props.carInfo.addresses || []);
  const carInfo = useSelector((state) => state.car.value);
  const disabled = props.pageType && props.pageType >= 3;

  useEffect(()=>{
    
  },[])

  useEffect(() => {
    if(props.pageType == 2 || props.pageType == 4){
      if(carInfo.addresses){
        setTmpAddresses(JSON.parse(carInfo.addresses));
      }
    }
  }, [carInfo]);

  useEffect(() => {
    if (Array.isArray(tmpAddresses)) {
      props.setCarInfo(prevState => ({
        ...prevState,
        addresses: tmpAddresses.filter(address => address.name !== '' || address.street_address !== '' || address.city !== '' || address.state !== '' || address.zip_code !== '' || address.country !== '' || address.googleMapLink !== '')
      }));
    }
  }, [tmpAddresses]);

  const handleLoadVin = async () => {
    setVinLoading(true);
    try{
      let res = await CarService.checkVinExist(props.carInfo.vin);
      if(res.data.success && res.data.bExist){
        props.setError({...props.error, vin: "This vin is already exist"})
        setVinLoading(false);
        return;
      }
      res = await CarService.getVinData(props.carInfo.vin);
      console.log("get vin data :", res);
      res= res.data
      if(res.message.code == 0 && res.data){
        let carName = `${user.name.split(' ')[0]}-${res.data.year}-${res.data.make}-${res.data.model}`
        props.setCarInfo({...props.carInfo, ...res.data, car_name: carName});
      }
      if(res.message && res.message.code !== 0){
        props.setError({...props.error, vin: res.message.message})
      }
    }catch(e){
      console.log("get vin data - error :", e)
    }
    setVinLoading(false);
  };
  
  const handleCarInfoChange = (e, newValue) => {
    let id = e.target ? e.target.id : e;
    let value = e.target ? e.target.value : newValue;
    
    if(!props.setCarInfo) return;
    // Ensure the value is always a string
    props.setCarInfo({...props.carInfo, [id]: value === undefined ? '' : value });
  
    if(value === '' && requiredFields.indexOf(id) !== -1){
      props.setError({...props.error, [id]: 'Please input this field'});
    }
    if(value !== '' && requiredFields.indexOf(id) !== -1){
      props.setError({...props.error, [id]: ''});
    }
  }

  const handleFeatureChange = (e) => {
    console.log(e.target.checked);
    let temp = [...props.carInfo.features];
    if(e.target.checked){
      temp.push(e.target.id);
    }else{
      temp.splice(temp.indexOf(e.target.id), 1);
    }
    props.setCarInfo({...props.carInfo, features: temp});
  }

  const onAddressHolder = (address,name) => {
    const number = name.split('-')[1];
    setTmpAddresses(prevAddresses => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[number] = address;
      return updatedAddresses;
    });
  }

  const addNewAddress = () => {
      setTmpAddresses(prevAddresses => [
        ...prevAddresses,
        { name: '', street_address: '', city: '', state: '', zip_code: '',country:'', googleMapLink: '' }
      ]);
  }

  const removeAddress = (index) => {
    setTmpAddresses(prevAddresses => prevAddresses.filter((_, i) => i !== index));
  }

  return (
    <div>
      <div>
          <Grid container spacing={{ xs: 1, sm: 2 }}>
            <Grid md={6} sm={8}>
              <Typography level='title-sm'>VIN*</Typography>
              <Input type="text" id="vin" value={props.carInfo.vin} onChange={handleCarInfoChange} disabled={props.pageType > 1}/>
              <Typography level='body-sm' color='danger' style={{display: props.error.vin == '' ? 'none' : 'block'}}>{props.error.vin}</Typography>
            </Grid>
            <Grid md={6} sm={4}>
              {props.pageType == 1 &&
                <Button variant="outlined" sx={{ mt: '20px'}} onClick={handleLoadVin} disabled={vinLoading || props.carInfo.vin == ''}>
                  {vinLoading ? "Getting..." : "Get From Vin"}
                </Button>
              }
            </Grid>

            <Grid md={4} sm={5} xs={12}>
              <Typography level='title-sm'>Type</Typography>
              <Select id="car_type" value={props.carInfo.car_type || ''} onChange={handleCarInfoChange} disabled={disabled}>
                <Option value="">Select a type</Option>
                {carTypes.map((item) => (
                  <Option key={item} value={item}>{item}</Option>
                ))}
              </Select>
            </Grid>
            <Grid md={2} sm={1} sx={{display: {xs: 'none', sm: 'block'}}}>
            </Grid>
            <Grid sm={3} xs={6}>
              <Typography level='title-sm'>Interior Color</Typography>
              <Input type="text" id="interior_color" value={props.carInfo.interior_color} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>
            <Grid sm={3} xs={6}>
              <Typography level='title-sm'>Exterior Color</Typography>
              <Input type="text" id="exterior_color" value={props.carInfo.exterior_color} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>

            <Grid sm={9} xs={12}>
              <Typography level='title-sm'>Car Name*</Typography>
              <Input type="text" id="car_name" value={props.carInfo.car_name} onChange={handleCarInfoChange} disabled={disabled}/>
              <label className="error-msg" style={{display: props.error.car_name == '' ? 'none' : 'block'}}>{props.error.car_name}</label>
            </Grid>
            <Grid sm={3} xs={4}>
              <Typography level='title-sm'>Year</Typography>
              <Input type="text" id="year" value={props.carInfo.year} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>

            <Grid sm={6} xs={8}>
              <Typography level='title-sm'>Car Model*</Typography>
              <Input type="text" id="model" value={props.carInfo.model} onChange={handleCarInfoChange} disabled={disabled}/>
              <label className="error-msg" style={{display: props.error.model == '' ? 'none' : 'block'}}>{props.error.model}</label>
            </Grid>
            <Grid sm={6} xs={12}>
              <Typography level='title-sm'>Manufacturer*</Typography>
              <Input type="text" id="manufacturer" value={props.carInfo.manufacturer} onChange={handleCarInfoChange} disabled={disabled}/>
              <label className="error-msg" style={{display: props.error.manufacturer == '' ? 'none' : 'block'}}>{props.error.manufacturer}</label>
            </Grid>

            <Grid sm={6} xs={6}>
              <Typography level='title-sm'>Trim</Typography>
              <Input type="text" id="trim" value={props.carInfo.trim} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>
            <Grid sm={6} xs={6}>
              <Typography level='title-sm'>Transmission</Typography>
              <Input type="text" id="transmission" value={props.carInfo.transmission} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>

            <Grid sm={6} xs={12}>
              <Typography level='title-sm'>License plate state / province</Typography>
              <Select id="plate_state" value={props.carInfo.plate_state} onChange={handleCarInfoChange} disabled={disabled}>
                {statesArray.map((item) => (
                  <Option key={item.code} value={item.code}>{item.label}</Option>
                ))}
              </Select>
            </Grid>
            <Grid xs={6}>
              <Typography level='title-sm'>Number of seats</Typography>
              <Select id="seats_number" value={props.carInfo.seats_number} onChange={handleCarInfoChange} disabled={disabled}>
                <Option data-display="Select" value="1">1 person</Option>
                {[...Array(10)].map((x, i) =>
                  <Option value={i+1} key={i}>{i+1} Seats</Option>
                )}
              </Select>
            </Grid>
            <Grid xs={6}>
              <Typography level='title-sm'>Number of doors</Typography>
              <Select id="doors_number" value={props.carInfo.doors_number} onChange={handleCarInfoChange} disabled={disabled}>
                <Option data-display="Select" value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
              </Select>
            </Grid>
            <Grid xs={6}>
              <Typography level='title-sm'>Fuel type</Typography>
              <Select id="fuel_type" value={props.carInfo.fuel_type} onChange={handleCarInfoChange} disabled={disabled}>
                <Option data-display="Select" value="Gas">Gas</Option>
                <Option value="Diesel">Diesel</Option>
                <Option value="Electric">Electric</Option>
                <Option value="Hydrogen">Hydrogen</Option>
              </Select>
            </Grid>
            {props.carInfo.fuel_type == 'Gas' && 
            <Grid xs={6}>
              <Typography level='title-sm'>Gas grade</Typography>
              <Select id="gas_grade" value={props.carInfo.gas_grade} onChange={handleCarInfoChange} disabled={disabled}>
                <Option data-display="Select" value="87">87</Option>
                <Option value="91">91</Option>
                <Option value="93">93</Option>
                <Option value="94">94</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Grid>}
            <Grid xs={6}>
              <Typography level='title-sm'>City MPG</Typography>
              <Input type="text" id="city_mpg" value={props.carInfo.city_mpg} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>
            <Grid xs={6}>
              <Typography level='title-sm'>Hwy MPG</Typography>
              <Input type="text" id="hwy_mpg" value={props.carInfo.hwy_mpg} onChange={handleCarInfoChange} disabled={disabled}/>
            </Grid>
          </Grid>
      </div>
      <Box sx={{mt: 3, mb: 3}}>
        <Typography level='h3'>Features</Typography>
        <Grid container spacing={2} sx={{mt: 2}}>
        {features.map((feature) => {
          let checked = props.carInfo.features.includes(feature.key);
          return !(props.activeNumber==2) || checked? (
            <Grid xs={6} sm={4} md={3} lg={2} key={feature.key}>
              <Checkbox 
                label={feature.label} 
                id={feature.key} 
                variant="soft" 
                checked={checked} 
                onChange={handleFeatureChange} 
                disabled={disabled}
              />
            </Grid>
          ) : null;
        })}
        </Grid>
      </Box>
      {props.pageType > 1 && 
        <Stack>
          <Typography level='title-sm'>Description</Typography>
          <Textarea
            slotProps={{
              textarea: {
                id: 'description',
              }
            }}
            minRows={3}
            sx={{width: 1}}
            value={props.carInfo.description}
            onChange={handleCarInfoChange}
            disabled={props.pageType == 4}
          >
            {props.carInfo.description}
          </Textarea>
        </Stack>
      }
      <Box sx={{mt: 3, mb: 3}}>
        <Typography level='h3'>Additional Addresses</Typography>
        {props.activeNumber == 2? (
          <Stack spacing={2} sx={{mt: 2}}>
            <Table sx={{ '& thead th:nth-child(1)': { width: '10%' } }}>
              <thead>
                <tr>
                  <th>No</th>
                  <th>street address</th>
                  <th>city</th>
                  <th>state</th>
                  <th>country</th>
                  <th>zip code</th>
                </tr>
              </thead>
              <tbody>
              {props.carInfo.addresses.map((address, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{address.street_address}</td>
                    <td>{address.city}</td>
                    <td>{address.state}</td>
                    <td>{address.country}</td>
                    <td>{address.zip_code}</td>
                  </tr>
              ))}
              </tbody>
            </Table>
          </Stack>
        ): (
          <Stack spacing={2} sx={{mt: 2}}>
            {Array.isArray(tmpAddresses) && tmpAddresses.map((address, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <AddressField onPlaceHolder={onAddressHolder} name={`n-${index}`} address={address} />
                <Button
                    variant="outlined"
                    color="danger"
                    onClick={() => removeAddress(index)}
                    sx={{ ml: 2, mt: 2 }}
                    style={{marginTop: 0}}
                  >
                    Remove
                  </Button>
              </Box> 
            ))}
            <Button 
              variant="solid" 
              sx={{ maxWidth: '200px' }} 
              onClick={addNewAddress}
            >
              Add Address
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
};

export default CarBasicDetail;
