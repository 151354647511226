import { Box, Grid, Stack, Step, Stepper, Button, StepIndicator, StepButton, Typography,  } from '@mui/joy';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CarService from '../../services/car.service';
import { requiredFields } from "../../utils/helper";
import { showToast } from "../../utils/toasts";
import CarBasicDetail from "./CarBasicDetail";
import Preview from "./Preview";
import Uploader from "./Uploader";
import Check from '@mui/icons-material/Check';


const steps = ['Car details', 'Photos', 'Summary'];

const initialDetails = { vin: "", car_type: "", interior_color: "", exterior_color: "", car_name: "", make: "", year: "", model: "", manufacturer: "", trim: "", transmission: "", engine: "", plate_number: "",
                    plate_state: "FL", seats_number: 5, doors_number: 4, fuel_type: 'Gas', gas_grade: 87, city_mpg: '', hwy_mpg: '', description: ''}

var defaultCarInfo = {...initialDetails, addresses:[],features: []};

const CarAdd = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [carInfo, setCarInfo] = useState(defaultCarInfo);
  const [error, setError] = useState({});

  const onStepClick = (index) => {
    if (activeStep == 0) {
      if(checkValid() == false) return;
    }
    setActiveStep(index);
  }

  const handleNext = async (e) => {
    e.preventDefault();

    if (activeStep == 0) {
      if(checkValid() == false) return;
      let res = await CarService.checkVinExist(carInfo.vin);
      if(res.data.success && res.data.bExist){
        setError({...error, vin: "This vin is already exist"})
        showToast("error", "This vin is already exist");
        return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 2) {
      
      let photos = [];
      previewImages.forEach((img) => {
        photos.push(img.uploadedFileName);
      });

      let data = { user_id: user.id, ...carInfo, photos: photos}

      CarService.addCar(data).then((response) => {
          console.log("response : ", response.data);
          navigate("/fleet");
        },
          (error) => {
            console.log("error:", error);
          }
      );
    }
  };
  const checkValid = () => {
    let temp = {...error};
    let valid = true;
    for(const field of requiredFields){
      if(carInfo[field] == ''){
        temp[field] = 'Please input this field'
        valid = false;
      }else{
        temp[field] = ''
      }
    }
    setError(temp);
    return valid;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Stack flex={1} direction='row' justifyContent={'center'}>
      <Stack sx={{width: '100%', maxWidth: 1200, px: 4, py: 2}}>
        <Box>
          <Typography level='h2'>List your car</Typography>
        </Box>
        <Box sx={{mt: 4}}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} 
                indicator={
                  <StepIndicator
                    variant={activeStep <= index ? 'soft' : 'solid'}
                    color={activeStep < index ? 'neutral' : 'primary'}
                  >
                    {activeStep <= index ? index + 1 : <Check />}
                  </StepIndicator>
                }
                sx={{
                  '&::after': {
                    ...(activeStep > index &&
                      index !== 2 && { bgcolor: 'primary.solidBg' }),
                  },
                }}>
                  <StepButton onClick={() => onStepClick(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <Box sx={{mt: 4}}>
            {activeStep == 0 ? 
              <CarBasicDetail carInfo={carInfo} activeNumber={activeStep} setCarInfo={setCarInfo} error={error} setError={setError} pageType={1}/>
            : activeStep == 1 ? 
              <>
                <Uploader setPreviewImages={setPreviewImages} previewImages={previewImages} />
                <Preview previewImages={previewImages} setPreviewImages={setPreviewImages} pageType={2}/>
              </>
            : <Grid container spacing={2}>
                  <Grid md={4} sm={12}>
                    <Stack direction={{xs: 'row', md: 'column'}} p={1} spacing={1} 
                      sx={{
                        overflow: 'auto',
                        maxHeight: {xs: 200, md: 800},
                      }}
                    >
                      {previewImages.map((element, index) => (
                          <Box key={index} 
                            sx={{
                              borderRadius: 8,
                              overflow: 'hidden',
                              '& img': {
                                height: 1,
                                width: 1,
                                objectFit: 'cover',
                              },
                              flex: 'none',
                              minHeight: {xs: 100, md: 1},
                              maxHeight: {md: 200},
                              maxWidth: { xs: 400, md: 1},
                            }}>
                            <img src={element.prevURL} alt={element.file.name}/>
                          </Box>
                        ))
                      }
                    </Stack>
                  </Grid>
                  <Grid md={8} sm={12}>
                    <CarBasicDetail carInfo={carInfo} disabled={true} activeNumber={activeStep} error={{}} setCarInfo={setCarInfo} pageType={3}/>
                  </Grid>
                </Grid>
            }
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3, justifyContent: 'space-between'}}>
                <Button variant="solid" color='success' disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                <Button variant="solid" color='primary' onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CarAdd;
