import CloseIcon from '@mui/icons-material/Close';
import {IconButton, Button, Typography, Box} from '@mui/joy';
import Checkbox from '@mui/joy/Checkbox';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import isBefore from 'date-fns/isBefore';
import React, { useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber } from 'rsuite';
import { getcoordinates, isValidZip } from '../../utils/helper';
import { FaClock } from 'react-icons/fa';
import Grid from '@mui/joy/Grid';
//import isSameWeek from 'date-fns/isSameWeek';
import { isSameWeek } from '../../utils/helper';
import * as helper from '../../utils/helper';

const requiredFields = ['price', 'distance', 'until']
const fields = {price: ['price'], distance: ['distance', 'until'],unavailable:['unavailable_start','unavailable_end','unavailable_start_time','unavailable_end_time'],removeUnavailable:['unavailable_start','unavailable_end','unavailable_start_time','unavailable_end_time']}
const initialSettings = {price: "50", distance: "50", until: new Date(),unavailable_start: new Date(),unavailable_end: new Date(), pickup_zip: null, pickup_radius: 0, return_zip: null, return_radius: 0,unavailable_start_time: new Date(new Date().setHours(0, 0, 0, 0)),unavailable_end_time: new Date(new Date().setHours(23, 59, 59, 0))}

const SettingsPopover = (props) => {
    
    const [settings, setSettings] = useState({...initialSettings, ...props.data});
    const [error, setError] = useState({});
    const [isWeeklyVisible, setIsWeeklyVisible] = useState(true);
    const isDisabled = props.isApplying.price || props.isApplying.distance || props.isApplying.unavailable;
    const isUnavailable=props.isUnavailable;
    const [addresses,setAddresses] = useState([]);
    const [selectedZipCode, setSelectedZipCode] = useState('');
    const [selectedZipCodeTo, setSelectedZipCodeTo] = useState('');
    useEffect(() => {
        debugger
        if (props.car && props.car.addresses) {
            try {
                const parsedAddresses = props.car.addresses;
                setAddresses(Array.isArray(parsedAddresses) ? parsedAddresses : Array.isArray(JSON.parse(parsedAddresses))?JSON.parse(parsedAddresses):[]);
            } catch (error) {
                console.error("Error parsing addresses:", error);
                setAddresses([]);
            }
        } else {
            setAddresses([]);
        }
    }, [props.car]);
    useEffect(() => {
        const unavailableStartTimeElement = document.querySelector('.unavailable_start_time');
        if (unavailableStartTimeElement && !unavailableStartTimeElement.hasListener) {
            unavailableStartTimeElement.addEventListener("click", () => {
                setTimeout(() => {
                    const width = unavailableStartTimeElement.offsetWidth;
                    const pickerPopupElement = document.querySelector('[data-testid="picker-popup"]');
                    if (pickerPopupElement) {
                        const rect = pickerPopupElement.getBoundingClientRect();
                        pickerPopupElement.style.left = `${rect.left - rect.width + width}px`;
                    }
                }, 10);
            });
            unavailableStartTimeElement.hasListener = true;
        }
        return () => {
            if (unavailableStartTimeElement) {
                unavailableStartTimeElement.removeEventListener("click", () => {});
            }
        };
    }, []);

    useEffect(() => {
        const unavailableStartTimeElement = document.querySelector('.unavailable_end_time');
        if (unavailableStartTimeElement && !unavailableStartTimeElement.hasListener) {
            unavailableStartTimeElement.addEventListener("click", () => {
                setTimeout(() => {
                    const width = unavailableStartTimeElement.offsetWidth;
                    const pickerPopupElement = document.querySelector('[data-testid="picker-popup"]');
                    if (pickerPopupElement) {
                        const rect = pickerPopupElement.getBoundingClientRect();
                        pickerPopupElement.style.left = `${rect.left - rect.width + width}px`;
                    }
                }, 10);
            });
            unavailableStartTimeElement.hasListener = true;
        }
        return () => {
            if (unavailableStartTimeElement) {
                unavailableStartTimeElement.removeEventListener("click", () => {});
            }
        };
    }, []);

    useEffect(() => {
        debugger;
        setSettings({...initialSettings, ...props.data});
        setSelectedZipCode(props.data.pickup_zip?props.data.pickup_zip.zip_code:'');
        setSelectedZipCodeTo(props.data.return_zip?props.data.return_zip.zip_code:'');
    }, [props.data])

    const handleChange = (name, value) => {
        if(name == 'pickup_zip' || name == 'return_zip'){
            setSettings({...settings, [name]: value.trim()});
        }else{
            setSettings({...settings, [name]: value});
        }

        if(requiredFields.includes(name)){
            if(!value){
                setError({...error, [name]: 'Please input valid value'});
            }else{
                setError({...error, [name]: ''});
            }
        }else{
            if(name == 'pickup_zip' || name == 'return_zip'){
                if(value == '' || isValidZip(value)){
                    setError({...error, [name]: ''});
                }else{
                    setError({...error, [name]: 'Please input valid zip'});
                }
            }
        }

        // Check if both unavailable_start and unavailable_end are in the same week
        let strCreatedAt='';
        let startDate = new Date(settings.unavailable_start);
        let endDate = new Date(settings.unavailable_end);
        let startTime = new Date(settings.unavailable_start_time);
        let endTime = new Date(settings.unavailable_end_time);
        if (name === 'unavailable_start' || name === 'unavailable_end') {
            
            if(name === 'unavailable_start')
                startDate=value;
            else
                endDate=value;
                //isDateInRange
            setIsWeeklyVisible(isSameWeek(startDate, endDate)); // Update visibility based on same week
            strCreatedAt=isUnavailableCal(startDate,endDate,startTime,endTime);
            if(strCreatedAt=='')
                props.setIsUnavailable(false);
            else
                props.setIsUnavailable(true);
        }

        if (name === 'unavailable_start_time' || name === 'unavailable_end_time') {
            if(name === 'unavailable_start_time')
                startTime=value;
            else
                endTime=value;

            strCreatedAt=isUnavailableCal(startDate,endDate,startTime,endTime);
            if(strCreatedAt=='')
                props.setIsUnavailable(false);
            else
                props.setIsUnavailable(true);
        }

    }

    const isUnavailableCal=(start,end,startTime,endTime)=>{
        debugger;
        let createdAt='';
        props.car.daily_settings.forEach(item => {
            const startDate=new Date(helper.getDateString(start)+'T'+helper.getTimeString(startTime));
            const endDate=new Date(helper.getDateString(end)+'T'+helper.getTimeString(endTime));
            const specificDate=new Date(item.unavailable_start.toLocaleString());
            if(helper.isDateInRange(startDate,endDate,specificDate) && item.block){
                createdAt=item.createdAt;
                return createdAt;
            }
                
        });
        return createdAt;
    }

    const checkValid = async (type) => {
        let temp = {...error};
        let valid = true;
        let tempSettings = {...settings};
        for(const field of fields[type]){
            if(settings[field]){
                temp[field] = ''
            }else{
                temp[field] = 'Please input valid value'
                valid = false;
            }
        }
        if(type == 'distance'){
            // if(settings.pickup_zip){
            //     let res = await getcoordinates(settings.pickup_zip);
            //     if(res){
            //         tempSettings.pickup_pos = res;
            //         temp['pickup_zip'] = ""
            //     }else{
            //         tempSettings.pickup_pos = '';
            //         temp['pickup_zip'] = "Can't get position"
            //     }
            // }else{
            //     tempSettings.pickup_pos = '';
            //     temp['pickup_zip'] = ''
            // }
            // if(settings.return_zip){
            //     let res = await getcoordinates(settings.return_zip);
            //     if(res){
            //         tempSettings.return_pos = res;
            //         temp['return_zip'] = ""
            //     }else{
            //         tempSettings.return_pos = '';
            //         temp['return_zip'] = "Can't get position"
            //     }
            // }else{
            //     tempSettings.return_pos = '';
            //     temp['return_zip'] = ''
            // }
        }
        setSettings(tempSettings);
        setError(temp)
        return {valid, tempSettings};
    }

    const handleApply = async (type) => {
        debugger;
        let {valid, tempSettings} = await checkValid(type);
        console.log(valid, tempSettings)
        if(valid){
            props.onApply(type, tempSettings)
        }
    }
    

    const handleChangeFromAddressForBook = (event,newValue,) => {
        const selectedAddress = addresses.find(address => address.zip_code === newValue);
        let tempSettings = {...settings};
        tempSettings.pickup_zip = selectedAddress;
        setSettings(tempSettings);
        setSelectedZipCode(newValue);
    };

    const handleChangeToAddressForBook = (event,newValue,) => {
        const selectedAddress = addresses.find(address => address.zip_code === newValue);
        let tempSettings = {...settings};
        tempSettings.return_zip = selectedAddress;
        setSettings(tempSettings);
        setSelectedZipCodeTo(newValue);
    };

    const renderDatePickerCell=()=>{
        console.log("hhhhhhh");
    }
    return (
        <div className={'pop-container'}>
            <IconButton className='close-btn' onClick={props.onClose}>
                <CloseIcon/>
            </IconButton>
            <Box mb={2}>
                <Typography level="title-lg">Edit price</Typography>
            </Box>
            <Typography level="body-sm">
                Set a custom price for a day or range of days. 
                Visit Pricing to adjust moreprice settings.
            </Typography>
            <div className='row-container'>
                <Typography level='title-sm'>Price</Typography>
                <InputNumber prefix="$" min={1} value={settings.price} onChange={(v)=>handleChange('price', v)}/>
                {error.price != '' && <p className='error-msg'>{error.price}</p>}
            </div>
            <div className='row-container'>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button variant='outlined' onClick={(e)=>handleApply('price')} disabled={isDisabled}>Apply Price</Button>
                </div>
            </div>
            <div className='row-container'>
                <Typography level='title-sm'>Maximum Daily Distance</Typography>
                <InputNumber prefix="Miles" min={1} value={settings.distance} onChange={(v)=>handleChange('distance', v)}/>
                {error.distance != '' && <p className='error-msg'>{error.distance}</p>}
            </div>
            <div className='zip-container'>
                <div className='left-column'>
                    <Typography level='title-sm'>Pickup Address</Typography>
                    {/* <Input value={settings.pickup_zip} onChange={(v)=>handleChange('pickup_zip', v)}/> */}
                    <Select 
                    value={selectedZipCode} 
                    name='FromAddressForBook' 
                    onChange={handleChangeFromAddressForBook}
                    sx={{ width: '100%', maxWidth: '150px' }}
                >
                    {addresses.length > 0 ? (
                        addresses.map((address, index) => (
                            <Option key={index} value={address.zip_code} >
                                {address.street_address} {address.city} {address.state} {address.country}
                            </Option>
                        ))
                    ) : (
                        <Option value="">No addresses available</Option>
                    )}
                    </Select>
                </div>
                <div className='right-column'>
                    <Typography level='title-sm'>Radius</Typography>
                    <InputNumber min={0} value={settings.pickup_radius} onChange={(v)=>handleChange('pickup_radius', v)}/>
                </div>
            </div>
                    {error.pickup_zip != '' && <p className='error-msg'>{error.pickup_zip}</p>}
            <div className='zip-container'>
                <div className='left-column'>
                    <Typography level='title-sm'>Return Address</Typography>
                    {/* <Input value={settings.return_zip} onChange={(v)=>handleChange('return_zip', v)}/> */}
                    <Select 
                        value={selectedZipCodeTo} 
                        name='ToAddressForBook' 
                        onChange={handleChangeToAddressForBook}
                        sx={{ width: '100%', maxWidth: '150px' }}
                    >
                        {addresses.length > 0 ? (
                            addresses.map((address, index) => (
                                <Option key={index} value={address.zip_code}>
                                    {address.street_address} {address.city} {address.state} {address.country}
                                </Option>
                            ))
                        ) : (
                            <Option value="">No addresses available</Option>
                        )}
                    </Select>
                </div>
                <div className='right-column'>
                    <Typography level='title-sm'>Radius</Typography>
                    <InputNumber min={0} value={settings.return_radius} onChange={(v)=>handleChange('return_radius', v)}/>
                </div>
            </div>
                    {error.return_zip != '' && <p className='error-msg'>{error.return_zip}</p>}
            <div className='row-container'>
                <Typography level='title-sm'>Date</Typography>
                <DatePicker shouldDisableDate={date => isBefore(date, new Date())}      format="MM-dd-yyyy" value={settings.until} disabled={true} onChange={(v)=>handleChange('until', v)}/>
                {error.until != '' && <p className='error-msg'>{error.until}</p>}
            </div>
            <div className='row-container'>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button variant='outlined' appearance="ghost" onClick={(e)=>handleApply('distance')} disabled={isDisabled}>Apply Distance</Button>
                </div>
            </div>
            <div className='row-container'>
                <Typography level="title-lg">Add unavailability</Typography>
                <Typography >Block off when your car is unavailable to be booked. Remember, you’ll get more trips by making your car available, especially on weekends and holidays.</Typography>
                <Typography level='title-sm'>Start date</Typography>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6} md={6}>
                        <DatePicker shouldDisableDate={date => isBefore(date, new Date())}      format="MM-dd-yyyy" value={settings.unavailable_start} onChange={(v)=>handleChange('unavailable_start', v)}/>
                    </Grid>
                    <Grid xs={6} md={6}>
                        <DatePicker className = "unavailable_start_time" format="HH:mm:ss" caretAs={FaClock} value={settings.unavailable_start_time} onChange={(v)=>handleChange('unavailable_start_time', v)}/>
                    </Grid>
                </Grid>
                
                <Typography level='title-sm'>End date</Typography>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6} md={6}>
                        <DatePicker shouldDisableDate={date => isBefore(date, new Date())}      format="MM-dd-yyyy" value={settings.unavailable_end} onChange={(v)=>handleChange('unavailable_end', v)}/>
                    </Grid>
                    <Grid xs={6} md={6}>
                        <DatePicker  className = "unavailable_end_time" format="HH:mm:ss" caretAs={FaClock}  value={settings.unavailable_end_time} onChange={(v)=>handleChange('unavailable_end_time', v)}/>
                    </Grid>
                </Grid>
                {isWeeklyVisible && ( // Conditionally render the checkbox
                    <Checkbox sx={{ marginTop: '10px', gap: 3 }} label="Repeat this unavailability weekly"  onChange={(e) => props.setIsRepeatWeekly(e.target.checked)}/>
                )}
                {error.unavailable != '' && <p className='error-msg'>{error.unavailable}</p>}
            </div>
            <div className='row-container'>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button variant='outlined' appearance="ghost" onClick={(e)=>handleApply('unavailable')} disabled={isUnavailable}>Set unavailability</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop:'5px'}}>
                    <Button variant='outlined' appearance="ghost" onClick={(e)=>handleApply('removeUnavailable')} disabled={!isUnavailable}>Remove unavailability</Button>
                </div>
            </div>
        </div>
    );
};

export default SettingsPopover;