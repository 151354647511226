import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import Register from "../../../components/Register";
import Footer from "../../../components/Footer";

const RegisterPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Header />
      <Register />
      <Footer />
    </Fragment>
  );
};
export default RegisterPage;
