import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { socialLogin } from "../../store/actions/auth";
import { REACT_APP_GOOGLE_OAUTH_CLIENT_ID } from '../../utils/constants';
import GoogleIcon from './GoogleIcon';

const GoogleButton = ({isRegister=false}) => {
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const dispatch = useDispatch();
  
  const handleCredentialResponse = (response) => {
      let decoded = jwtDecode(response.credential);
      console.log("decoded:", decoded);
      dispatch(socialLogin(response.credential))
  }
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
        client_id: REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
    document.getElementById("btnGoogle"),
        { theme: "outline", size: "large", width: '500', height: '37' }  // customization attributes
    );
  }, []);

  return (
    // <a href={getGoogleUrl(from)}>
      <Button variant="soft" color="neutral" fullWidth startDecorator={<GoogleIcon />}>
          Continue with Google
          <Box id="btnGoogle" sx={{position: 'absolute', opacity: 0, width: '100%', overflow: 'hidden', '& iframe': { width: 'calc(100%)!important', maxHeight: '37px!important', margin: '0!important'}}}></Box>
      </Button> 
    // </a>
  );
};

export default GoogleButton;
