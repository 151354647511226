import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import Fleet from "../../../components/Fleet";
import Footer from "../../../components/Footer";
import {SharedSearchParamProvider} from '../../../hooks/useSearchParam';
const FleetPage = () => {
  const { t } = useTranslation();

  return (
    <SharedSearchParamProvider>
      <div className="flex-container">
        <Header />
        <Fleet />
      </div>
    </SharedSearchParamProvider>
  );
};
export default FleetPage;
