import statesArray from './states.json'
import countries from './countries.json'
import driveCountries from './drive_countries.json'
import manufactureArray from './manufacturers.json'
import * as constants from './constants'
import axios from 'axios';
export const requiredFields = ["vin", "car_name", "model", "manufacturer"] 
export const carTypes = ["Car", "Minivan", "Van", "Suv", "Truck", "Cargo Van", "Other"];
export const manufacturers = manufactureArray;
export function isSameWeek(date1, date2, startOfWeek = 'sunday') {
    // Parse the dates
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Normalize the dates to the start of the week
    const dayOffset = startOfWeek.toLowerCase() === 'monday' ? 1 : 0;
    const weekStart1 = new Date(d1.setDate(d1.getDate() - ((d1.getDay() + 7 - dayOffset) % 7)));
    const weekStart2 = new Date(d2.setDate(d2.getDate() - ((d2.getDay() + 7 - dayOffset) % 7)));

    // Compare the start of the week
    return weekStart1.getFullYear() === weekStart2.getFullYear() &&
           weekStart1.getMonth() === weekStart2.getMonth() &&
           weekStart1.getDate() === weekStart2.getDate();
}

export function getAutoObject(code, options){
    if(code === '') return null;
    for(const item of options){
        if(item.code === code) return item;
    }
    return null;
}

export function getStateNameFromAbbr(abbr){
    let uabbr = abbr.toUpperCase();
    for(const item of statesArray){
        if(item.code == uabbr) return item.label;
    }
    return '';
}

export function getStateAbbrFromName(name){
    let lname = name.toLowerCase();
    for(const item of statesArray){
        if(item.name.toLowerCase() == lname) return item.code;
    }
    return '';
}

export function getImageUrl(fileName, kind='cars'){
    if(process.env.NODE_ENV == 'development'){
        return `http://localhost:8080/public/images/${kind}/` + fileName;
    }else{
        return `/public/images/${kind}/` + fileName
    }
}

export function getShortDateTimeFromDate(param){
    let date = new Date(param);
    const formattedTime = date.toLocaleString('en-US', {
        month: 'short', // Abbreviated month name (e.g., "Nov")
        day: 'numeric', // Day of the month (e.g., "25")
        hour: '2-digit', // 2-digit hours (e.g., "07")
        minute: '2-digit', // 2-digit minutes (e.g., "00")
        hour12: true, // Use 12-hour clock with AM/PM
    });
    return formattedTime;
}

export function getDateString(date){
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    let day = date.getDate().toString().padStart(2, '0');
    let dateOnly = `${year}-${month}-${day}`;

    return dateOnly;
}
export function getUTC0FromLocalTime0(){
    let localTime = new Date();
    localTime.setHours(0, 0, 0, 0); // Set to 00:00:00 (midnight) in local time
    
    let utcTime = localTime.toISOString().slice(11, 19); // Extract only the time portion

    return utcTime;
}
export function convertUTC0ToLocalTime(utcTime) {
    // Create a Date object assuming the input is in 'HH:MM:SS' format in UTC (UTC0)
    let today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
    let utcDateTime = new Date(today + 'T' + utcTime + 'Z'); // Combine date with time and treat it as UTC

    // The Date object will automatically adjust the time to local time zone
    return utcDateTime;
}

export function convertLocalTimeToUTC0(localTime) {
    // Create a Date object from the local time input
    const localDateTime = new Date(localTime);
    
    // Convert to UTC0 by getting the UTC time string
    const utcTimeString = localDateTime.toISOString().slice(11, 19); // Extract only the time portion in 'HH:MM:SS'
    
    return utcTimeString;
}
export function getTimeStringFromDate(utcdatetime) {
    // Convert to UTC0 by getting the UTC time string
    const utcTimeString = new Date(utcdatetime).toISOString().slice(11, 19); // Extract only the time portion in 'HH:MM:SS'
    
    return utcTimeString;
}
export function convertUTCToLocal(date){
    return new Date(date.toLocaleString())
}
export function getPercentofDiff(startUTC,endUTC){
    const unavailableStartUTC = startUTC;
    const unavailableEndUTC = endUTC;

    // Convert the times to date objects
    const startTimeUTC = new Date(`1970-01-01T${unavailableStartUTC}Z`); // using 'Z' to specify it's in UTC
    const endTimeUTC = new Date(`1970-01-01T${unavailableEndUTC}Z`);

    // Get the local time equivalents
    const unavailableStartLocal = new Date(startTimeUTC.toLocaleString());
    const unavailableEndLocal = new Date(endTimeUTC.toLocaleString());

    // Calculate the duration between start and end times in milliseconds
    let duration = unavailableEndLocal - unavailableStartLocal;

    // Handle crossing midnight case
    if (duration < 0) {
    duration += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
    }

    // Calculate the percentage of 24 hours
    const hoursInDay = 24 * 60 * 60 * 1000;
    const percentage = (duration / hoursInDay) * 100;
    return percentage;
}
export function getDateTimeString(date){
    return date.toISOString();
}
export function getDateTimeObject(date){
    return new Date(date.toISOString());
}
export function getTimeString(timeInput) {
    if (typeof timeInput === 'string' && /^\d{2}:\d{2}:\d{2}$/.test(timeInput)) {
        // If it's a time string in HH:MM:SS format
        const [hours, minutes,seconds] = timeInput.split(':');
        return `${hours}:${minutes}:${seconds}`;
    }

    const date = timeInput instanceof Date ? timeInput : new Date(timeInput);
    if (isNaN(date.getTime())) {
        return ''; // Return empty string for invalid dates
    }
    
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit',second: '2-digit', hour12: false });

}

export function getDateStringWithYear(diff){
    const year = (new Date()).getFullYear() + diff;
    return `${year}-01-01`;
}

export function getDateFromString(dateString) {
    var parts = dateString.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function getStringFromDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the time as "YYYY-MM-DDThh:mm"
    const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedTime;
}

export function buildQueryString(params) {
    let temp = [];
    for (const [key, value] of Object.entries(params)) {
        if(value && value != ''){
            temp.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }
    return temp.join('&');
}

export function isValidZip(value){
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value.trim());
}

export async function getcoordinates(address){
    try {
        let key = constants.REACT_APP_GOOGLE_MAP_KEY
        let response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key='+key);
        const latitude = response.data.results[0].geometry.location.lat;
        const longitude = response.data.results[0].geometry.location.lng;
        return latitude + "," + longitude;
    }catch(e){
        return '';
    }
}

export const isDateInRange = (pickupDatetime, returnDatetime, specificDatetime) => {
    return pickupDatetime < specificDatetime && specificDatetime < returnDatetime;
};

export const isDateRangeInRange = (startDatetime, endDatetime, evalStartDatetime,evalEndDatetime) => {
    return (startDatetime <= evalEndDatetime && endDatetime >= evalStartDatetime);
};

export const features = [
    { key: "all_whell_drive", label:  "All-Wheel Drive"},
    {key: "aux_input", label: "AUX Input"},
    {key: "blind_spot_warning", label: "Blind Spot Warning"},
    {key: "convertible", label: "Convertible"},
    {key: "keyless_entry", label: "Keyless Entry"},
    {key: "snow_tires_chains", label: "Snow Tires Or Chains"},
    {key: "usb_charger", label: "USB Charger"},
    {key: "android_auto", label: "Android Auto"},
    {key: "backup_camera", label: "Backup Camera"},
    {key: "bluetooth", label: "Bluetooth"},
    {key: "gps", label: "GPS"},
    {key: "pet_friendly", label: "Pet Friendly"},
    {key: "sunroof", label: "Sunroof"},
    {key: "usb_input", label: "USB Input"},
    {key: "apple_carplay", label: "Apple CarPlay"},
    {key: "bike_rack", label: "Bike Rack"},
    {key: "child_seat", label: "Child Seat"},
    {key: "heated_seats", label: "Heated Seats"},
    {key: "ski_rack", label: "Ski Rack"},
    {key: "toll_pass", label: "Toll Pass"},
    {key: "wheelchair_accessible", label: "Wheelchair Accessible"}
  ]

  export function getFeatureLabel(param){
    for(let ind in features){
        if(features[ind].key == param){
            return features[ind].label;
        }
    }
    return '';
  }