import { SET_CAR_INFO, GET_CAR_INFO } from "../actions/types";



const initialState = {
    value: '',
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_CAR_INFO:
            return {
                ...state,
                value: action.payload,
            };

        case GET_CAR_INFO:
            return {
                ...state,
            };

        default:
            return state;
    }
}