import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import Error from "../../../components/Error";
import Footer from "../../../components/Footer";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-container">
      <Header />
      <Error />
      <Footer />
    </div>
  );
};
export default ErrorPage;
