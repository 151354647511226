import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Assuming you're using redux
import { Input, Typography } from "@mui/joy";

const AddressField = ({ onPlaceHolder, name, address }) => {
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch(); //Hook to access the dispatch function from redux
  const formatAddress = (address) => {
    if (!address) return '';
    const parts = [
      address.street_address,
      address.city,
      address.state,
      address.country
    ].filter(Boolean);
    return parts.join(', ');
  };
  const [inputValue, setInputValue] = useState(formatAddress(address));
  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ['address'] }
      );
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }

    // Cleanup function
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setInputValue(formatAddress(address) || '');
  }, [address]);

  const handlePlaceSelect = () => {
    let addressObject = autocompleteRef.current.getPlace();
    let address = addressObject.address_components;
    const addressState = {
      name: addressObject.name,
      street_address: `${address[0]?.long_name || ''} ${address[1]?.long_name || ''}`.trim(),
      city: address.find(comp => comp.types.includes('locality'))?.long_name || '',
      state: address.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || '',
      country: address.find(comp => comp.types.includes('country'))?.short_name || '',
      zip_code: address.find(comp => comp.types.includes('postal_code'))?.short_name || '',
      googleMapLink: addressObject.url || '',
    };
    const inputName = inputRef.current ? inputRef.current.name : '';
    onPlaceHolder(addressState,inputName);
    //onSearchChange({ target: { name, value: addressState.street_address } });
  };



  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    const addressState = {};
    if(newValue==""){
      const inputName = inputRef.current ? inputRef.current.name : '';
      onPlaceHolder(addressState,inputName);
    }
  };

  return (
    <div style={{width: '100%'}}>
      <Input style={{ display: 'none' }} />
      <div className="MuiInput-root MuiInput-variantOutlined MuiInput-colorNeutral MuiInput-sizeMd css-avlfzq">
        
        <input
          ref={inputRef}
          className="MuiInput-input css-1u0jcuo"
          type="text"
          size='sm'
          variant="soft"
          placeholder=""
          name={name}
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default AddressField;