import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../components/header";
import CarList from "../../../components/CarList";
import Footer from "../../../components/Footer";
import {SharedSearchParamProvider} from '../../../hooks/useSearchParam';
const CarListingPage = () => {
  const { t } = useTranslation();

  return (
    <SharedSearchParamProvider>
      <Fragment>
        <Header />
        <CarList />
      </Fragment>
    </SharedSearchParamProvider>
  );
};
export default CarListingPage;
