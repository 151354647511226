import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Stack } from "@mui/joy";

const Footer = ({ customStyles }) => {

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <footer style={customStyles}>
      <Stack direction='row' alignItems={'center'} justifyContent={'center'} gap={1} sx={{height: 40, bgcolor: 'background.level1'}}>
          <Link to="/" onClick={onClick}>
            <FacebookIcon />
          </Link>
          <Link to="/" onClick={onClick}>
            <LinkedInIcon />
          </Link>
          <Link to="/" onClick={onClick}>
            <TwitterIcon/>
          </Link>
      </Stack>
    </footer>
  );
};

export default Footer;
